import React from "react";

const Filter = ({ handleSearch, submitSearch }) => {
  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between  w-100">
        <div className="d-flex gap-2"></div>
        <div className="form-group text-end mt-1  mx-4 ">
          <div className="input-group">
            <input
              onChange={handleSearch}
              className="form-control form-control-sm"
              placeholder="Search here..."
            />
            <div className="input-group-addon border-bottom">
              <i
                onClick={submitSearch}
                style={{ fontSize: "20px", cursor: "pointer" }}
                className="fa-solid fa-magnifying-glass mt-2 mx-2"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
