import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import "./doughnout.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formatNumber from "../../helpers/NumberFormat";

const DougnoutChart = ({ main }) => {
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  var options = {
    cutout: "82%",
    elements: {
      center: {
        color: "red", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25, // Default is 25 (in px), used for when text wraps
      },
    },
    // cutout: isMobile ? "300%" : 80,
    borderColor: "#FCFCFC",
  };
  const data = {
    labels: [],
    datasets: [
      {
        hoverOuterGlowWidth: "rgb(255, 255, 0)",
        label: "€",
        data: [main?.incoming, main?.outgoing],

        backgroundColor: ["rgb(31, 80, 170)", "rgb(93, 209, 194)"],
        borderColor: ["#FCFCFC", "white"],
      },
    ],
  };
  return (
    <div
      className={`bg-white shadow-sm w-100 rounded doughnut-wrapper ${
        isMobile ? "h-auto py-4" : ""
      }`}
    >
      {" "}
      <div className="text-start d-flex p-4 justify-content-between">
        <h5 className="">Charges</h5>
        <p
          onClick={() => navigate("/transactions")}
          type="button"
          className="text-grey "
        >
          Show More
        </p>
      </div>
      {formatNumber(main?.incoming) ? (
        <div className="w-40 m-auto">
          <Doughnut
            style={{
              filter: `drop-shadow(0px 0px 2px red}`,
            }}
            data={data}
            options={options}
            // plugins={plugins}
          />
        </div>
      ) : (
        <div className="text-center mt-4 w-95 m-auto ">
          <h5 className="fw-bold">No Incoming Balance</h5>
        </div>
      )}
      <div className="w-100">
        <div className="w-100">
          <div className="d-flex border justify-content-start w-80 m-auto bg-light shadow mt-5">
            <div className="w-10 p-4">
              <div className="incoming"></div>
            </div>
            <div className=" w-90  ">
              {" "}
              <p className=" mt-3">
                <strong>Income:</strong> {formatNumber(main?.incoming)} €
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-start w-80 m-auto bg-light shadow mt-3">
            <div className="w-10 p-4">
              <div className="outgoing"></div>
            </div>
            <div className="w-90 ">
              {" "}
              <p className="mt-3">
                <strong>Outgoing:</strong> {formatNumber(main?.outgoing)} €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DougnoutChart;
