import React from "react";
import "./exchange.scss";

const ExcahngeRates = () => {
  return (
    <div className=" exchange mt-5 w-100 shadow-sm">
      {" "}
      {/* <iframe
        src="https://fxpricing.com/fx-widget/market-currency-rates-widget.php?id=1,2,3,5,14,20"
        width="100%"
        height={320}
        style={{ border: "1px solid #eee" }}
        className="shadow-sm p-3 mb-5 bg-white rounded"
      /> */}
      <iframe
        title="fx"
        src="https://wise.com/gb/currency-converter/fx-widget/converter?sourceCurrency=GBP&targetCurrency=EUR"
        height="130px"
        width="100%"
        border="0"
        style={{ border: "0", marginTop: "-30px" }}
        allowtransparency="true"
        scrolling="no"
      ></iframe>
      <div className="mx-4">
        {" "}
        <p className="fw-bold">Convert Your Value</p>
      </div>
    </div>
  );
};

export default ExcahngeRates;
