import React from "react";

const Welcome = ({ main }) => {
  const locationTest =
    window.location.pathname === "/" ? "/" : window.location.pathname;

  return (
    <div className="w-95 m-auto  ">
      <div>
        {" "}
        <h3 className="text-primary">Dashboard</h3>
        <small className="text-grey">You are in the main panel</small>
      </div>
    </div>
  );
};

export default Welcome;
