import React, { useState, useEffect } from "react";
import Form from "./Form";
import { useGetALLClientsListQuery } from "services/mainApi";

const LatestTransactionCmp = () => {
  const [selectedClient, setSelectedClient] = useState(false);

  const {
    data: allClients,
    isLoading: loading,
    isError: error,
  } = useGetALLClientsListQuery();

  return (
    <div className="border" style={{ overflow: "auto", height: "90%" }}>
      <div className="step-1 ">
        <div className="pt-5">
          <div className="mx-4  text-center">
            <div>
              <h5>Please Select a Client To Make a Transfer</h5>
            </div>
            <div className="dropdown w-100">
              <button
                className="btn btn-primary dropdown-toggle w-100"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedClient ? selectedClient.name : "Client"}
              </button>
              <div
                className="dropdown-menu w-100"
                aria-labelledby="dropdownMenuButton"
              >
                {allClients?.clients?.map((el, index) => (
                  <a
                    onClick={() =>
                      setSelectedClient({
                        ...selectedClient,
                        id: el.id,
                        name: el.first_name + " " + el.last_name,
                      })
                    }
                    className="dropdown-item"
                  >
                    {el.first_name} {el.last_name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedClient ? <Form id={selectedClient?.id} /> : ""}
    </div>
  );
};

export default LatestTransactionCmp;
