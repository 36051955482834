import React, { useState } from "react";
import History from "./History/History";
import Transfer from "./Transfer/Transfer";

const TransactionsAdminComp = () => {
  const [isHistory, setIsHistory] = useState(true);
  return (
    <div>
      <div className="d-flex justify-content-between  w-80 m-auto mt-5">
        <div
          onClick={() => setIsHistory(true)}
          className={`history  ${
            isHistory ? "bg-active" : ""
          } pt-2 border-right w-50 border shadow-sm text-center`}
        >
          <h4>History</h4>
        </div>
        <div
          onClick={() => setIsHistory(false)}
          className={`transfer ${
            isHistory ? "" : "bg-active"
          } w-50 border  text-center pt-2`}
        >
          {" "}
          <h4>Make a Transfer</h4>
        </div>
      </div>
      <div className="mt-5 m-2 ">
        {isHistory ? <History /> : <Transfer setIsHistory={setIsHistory} />}
      </div>
    </div>
  );
};

export default TransactionsAdminComp;
