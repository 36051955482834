import React, { useState } from "react";
import "./table.scss";
import Details from "./Details.jsx";
import moment from "moment";
import {
  useLazyGetSingleAdminTransactionsQuery,
  useGetAdminMainQuery,
  useUpdateStatusMutation,
  useDeleteTransactionMutation,
  useUpdateStatusTransactionMutation,
} from "services/mainApi";
import { toast } from "react-toastify";
import formatNumber from "../../../../helpers/NumberFormat.jsx";

const Table = ({ transactions }) => {
  const [openModal, setOpenModal] = useState(false);
  const [getDetails, { data: details }] =
    useLazyGetSingleAdminTransactionsQuery();

  const { data: main } = useGetAdminMainQuery();

  const [updateStatusTransaction] = useUpdateStatusTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  //change the api for details
  const triggerModal = () => {
    setOpenModal(!openModal);
  };
  const getDetailsFunc = (id) => {
    triggerModal();
    getDetails(id);
  };

  const updateTransactionStatus = async (id, status_id) => {
    const result = await updateStatusTransaction({
      id: id,
      status_id: status_id,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
    }
  };

  const deleteTrans = async (id) => {
    const result = await deleteTransaction(id);
    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Deleted Succesfully");
    }
  };

  return (
    <div className="table-wrapper">
      <Details
        details={details}
        openModal={openModal}
        triggerModal={triggerModal}
      />
      <div className="p-3"></div>
      <table className="table m-auto ">
        <thead className="">
          <tr className="bg-light">
            <th scope="col " className="text-grey fw-normal text-center">
              ID
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Amount
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Status
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Type
            </th>
            <th scope="col" className="text-grey fw-normal text-center">
              Date
            </th>
            <th scope="col" className="text-grey fw-normal text-center"></th>
          </tr>
        </thead>
        <tbody>
          {transactions?.length > 0 ? (
            transactions?.map((el, index) => (
              <tr key={index}>
                <th className="text-primary text-center" scope="row">
                  {el.reference_code}
                </th>
                <td className=" fw-bold text-center">
                  {formatNumber(el.amount)} €
                </td>

                <td className=" fw-bold text-center">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn bg-white dropdown-toggle border-0"
                      style={{ color: el?.status?.color }}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {el?.status?.name}
                    </button>
                    <div className="dropdown-menu">
                      {main?.statuses?.map((item, index) => {
                        return (
                          <div className="d-flex justify-content-between  w-95 m-auto">
                            <h6
                              onClick={() =>
                                updateTransactionStatus(el.id, item.id)
                              }
                              className="status-item w-75"
                              type="button"
                            >
                              {item.name}
                            </h6>
                            <div
                              className="color-picker-round mt-0"
                              type="button"
                              style={{
                                backgroundColor: item.color,
                                boxShadow: "0 0 25px" + item.color,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </td>
                <td className=" fw-bold bg-light text-center text-orange">
                  {el.type === "in" ? "Incoming" : "Outgoing"}
                </td>
                <td className=" fw-bold text-center">
                  {" "}
                  {moment(el.created_at).format("DD/MM/YYYY")}
                </td>
                <td className="">
                  <div
                    className="d-flex justify-content-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className=" fa-solid fa-trash"
                      style={{ color: "red" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={() => deleteTrans(el.id)}
                    ></i>
                    {/* <i
                      className="info fa-solid fa-circle-info"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Details"
                    ></i>
                    <i
                      className="download fa-solid fa-download"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download Your Pdf"
                    ></i> */}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <div></div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
