import React, { useState, useEffect } from "react";
import ExchangeRates from "./ExcahngeRates";
import Table from "./Table";
import MainInfo from "./MainInfo";
import Welcome from "./Welcome";
import DougnoutChart from "./DougnoutChart";
import ShortInvestments from "./ShortInvestments";
import "./home.scss";
import {
  useGetMainQuery,
  useGetSingleClientTransactionsQuery,
} from "services/clientApi";
import { useSelector } from "react-redux";

const HomeComp = () => {
  const { data: main, isLoading: loading, isError: error } = useGetMainQuery();
  const { data: transactions, isLoading: loadingTransactions } =
    useGetSingleClientTransactionsQuery(main?.user?.id);
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  return (
    <div className="w-100 home-wrapper ">
      {" "}
      <Welcome main={main} />
      <div className="d-flex justify-content-between ">
        <div className="w-100 m-auto">
          {" "}
          <div>
            <div className="text-end mx-5 "></div>
            <MainInfo main={main} />
          </div>
          <div
            className={` ${
              isMobile
                ? " w-95 m-auto "
                : "d-flex justify-content-between gap-5 w-95 m-auto mt-4"
            }`}
          >
            <div
              className={`${
                isMobile ? "w-95  m-auto mt-4" : "w-70 d-flex gap-5 m-auto"
              }  `}
            >
              <Table
                loading={loadingTransactions}
                transactions={transactions}
              />
              <div className={`${isMobile ? "w-100 m-auto mt-5" : "w-100"}`}>
                <DougnoutChart main={main} />
              </div>
            </div>
            {/* <div className={`${isMobile ? "w-95 m-auto mt-4" : "w-70"} `}></div> */}

            <div className={`${isMobile ? "w-95 m-auto my-4" : "w-50"} `}>
              <ShortInvestments />
            </div>
          </div>
        </div>
      </div>{" "}
      <div></div>
    </div>
  );
};

export default HomeComp;
