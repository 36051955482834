import React from "react";

const PrivateRouteAdmin = (Component) => (props) => {
  const isLoggedIn = localStorage.getItem("auth");
  const url = new URL(window.location.href);
  const isAdmin =
    localStorage.getItem("isAdmin") || url.searchParams.get("isAdmin");
  const urlToken = url.searchParams.get("access_token");
  const roleUrl = url.searchParams.get("role");

  if ((isLoggedIn && isAdmin) || (urlToken && roleUrl === "admin"))
    return <Component {...props} />;
  return window.location.replace("https://techno-robotix.tech");
};

export default PrivateRouteAdmin;
