import React from "react";
import Video from "../../assets/video/investing.mp4";
import "./investcomp.scss";

const WhyInvest = () => {
  return (
    <div className="bg-white why-invest">
      <div className="d-flex justify-content-between w-95 m-auto">
        <div className="w-75 mt-4">
          <div className="my-4">
            <h4>Why Invest online with UniBank?</h4>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex gap-3">
                <div className="w-15">
                  <i
                    style={{ fontSize: "50px" }}
                    className="text-primary fa-solid fa-bullseye"
                  />
                </div>
                <div className="w-85">
                  <h5>Platforms that meet your evolving needs</h5>
                  <p className="custom-font-size">
                    Unibank’s online investing services grow with you. You may
                    start with one platform to get accustomed to online
                    investing before moving on to another. Or you may want to
                    use more than one. Rest easy knowing there’s always a
                    product for you and your evolving investing
                    strategy.Unibanks online investing services grow with you.
                    You may start with one platform to get accustomed to online
                    investing before moving on to another. Or you may want to
                    use more than one. Rest easy knowing there’s always a
                    product for you and your evolving investing strategy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div className="d-flex gap-3">
                <div className="w-15 text-center">
                  <i
                    style={{ fontSize: "50px" }}
                    className="text-primary fa-regular fa-file"
                  />
                </div>
                <div className="w-85">
                  <h5>World-class research and tools</h5>
                  <p className="custom-font-size">
                    Dive into a wealth of research by Unibank’s team of economic
                    experts. Stay on top of all the economic trends to ensure
                    you’re always on the right path to success. And access the
                    tools you need to make the most of your portfolio.Dive into
                    a wealth of research by Unibanks ’s team of economic
                    experts. Stay on top of all the economic trends to ensure
                    you’re always on the right path to success. And access the
                    tools you need to make the most of your portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {" "}
              <div className="d-flex gap-3">
                <div className="text-center w-15">
                  <i
                    style={{ fontSize: "40px" }}
                    className="text-primary fa-regular fa-comments "
                  />
                </div>
                <div className="w-85">
                  <h5>Support when you need it</h5>
                  <p className="custom-font-size">
                    Dedicated support teams for each online investing platform
                    are there when you need them – so you’re never investing
                    alone. And they’re available in-person, online or over the
                    phone.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div className="d-flex gap-3">
                <div className="text-center w-15">
                  <i
                    style={{ fontSize: "50px" }}
                    className="text-primary fa-regular fa-clock"
                  />
                </div>
                <div className="w-85">
                  <h5>24/7 account access</h5>
                  <p className="custom-font-size">
                    No matter which platform you choose, access your portfolio
                    online, anytime online. Check your performance or make
                    changes to your portfolio on your terms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-image">
          <video
            src={Video}
            autoPlay
            muted
            loop
            width="750"
            height="500"
          ></video>
        </div>
      </div>
    </div>
  );
};

export default WhyInvest;
