import React from "react";

const PrivateRoute = (Component) => (props) => {
  const url = new URL(window.location.href);
  const isLoggedIn =
    localStorage.getItem("auth_client") || url.searchParams.get("access_token");
  const isClient =
    localStorage.getItem("isClient") || url.searchParams.get("isClient");
  if (isLoggedIn && isClient) return <Component {...props} />;
  return window.location.replace("/login");
};

export default PrivateRoute;
