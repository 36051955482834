import React from "react";
import { createPortal } from "react-dom";

const ModalRoot = (props) => {
  const modalRoot = document.getElementById("modal");
  return createPortal(props.children, modalRoot);
};

function Modal({
  open,
  handleCancel,
  childrenHeader,
  children,
  title = null,
  footerButtons,
}) {
  if (!open) return null;

  return (
    <>
      <ModalRoot>
        <div
          className={"modal fade" + (open ? "show" : "")}
          style={{ display: "block " }}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog shadow" role="document">
            <div onClick={(e) => e.stopPropagation()} className="modal-content">
              <div className="modal-header d-flex justify-content-end px-2 bg-primary">
                <i
                  type="button"
                  onClick={handleCancel}
                  className="fa-solid text-white fa-xmark fs-1 px-3"
                ></i>
              </div>
              <div className="modal-body"> {children}</div>

              <div className="modal-footer py-1 border-0 pb-4 ">
                {footerButtons?.map((button) => (
                  <button
                    key={button.title}
                    onClick={button.func}
                    type="button"
                    className={`btn ${
                      button?.title === "Cancel"
                        ? "bg-transparent"
                        : "btn-info text-white"
                    } `}
                  >
                    {button.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ModalRoot>
    </>
  );
}

export default Modal;
