import React, { useEffect } from "react";
import router from "./route";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useGetMainQuery, useLazyGetMainQuery } from "services/clientApi";
import { setScreenWidth } from "./slices/slices";
import "./index.scss";

const App = () => {
  const url = new URL(window.location.href);
  const urlToken = url.searchParams.get("access_token");
  const role = url.searchParams.get("role");
  const isClient = url.searchParams.get("isClient");

  const dispatch = useDispatch();
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const [getMain] = useLazyGetMainQuery();

  useEffect(() => {
    if (role) {
      localStorage.setItem("auth", urlToken);
      localStorage.setItem("isAdmin", role);
    } else {
      if (isClient) {
        localStorage.setItem("auth_client", urlToken);
        localStorage.setItem("isClient", isClient);
        getMain();
      }
    }
  }, [urlToken]);

  useEffect(() => {
    window.addEventListener("resize", () =>
      dispatch(setScreenWidth(window.innerWidth))
    );
  }, [window.innerWidth]);

  return (
    <>
      <ToastContainer />
      <div className="container-lg">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
