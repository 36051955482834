import React from "react";
import Modal from "shared/Modal/Modal";

const EmailModal = ({ content, openModal, triggerModal }) => {
  return (
    <div>
      {" "}
      <Modal open={openModal} handleCancel={triggerModal}>
        <div className="w-95 m-auto border-bottom">
          <h3>Contact </h3>
        </div>
        <div className="d-flex justify-content-between w-95 m-auto">
          <h5 className="text-primary">{content}</h5>
        </div>
      </Modal>
    </div>
  );
};

export default EmailModal;
