import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

const customBackOff = async (attempt = 0, maxRetries = 0) => {
  const attempts = Math.min(attempt, maxRetries);

  await new Promise((resolve) => {
    setTimeout(resolve, attempts * 1000);
  });
};

const baseQuery = (baseQueryOptions) => async (args, api, extraOptions) => {
  const result = await fetchBaseQuery(baseQueryOptions)(
    args,
    api,
    extraOptions
  );

  if (result.error) {
    toast.error(result.error.data.error, {
      toastId: result.error.data.error,
    });
  }
  return result;
};

const isLocalHost = !!(
  location.hostname === "localhost" || location.hostname === "127.0.0.1"
);

export const clientApi = createApi({
  reducerPath: "clientApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: "https:",
  // }),
  baseQuery: baseQuery({
    baseUrl: isLocalHost
      ? "http://93.115.31.120:8002"
      : window.location.protocol + "/backend",
    backoff: customBackOff,
    maxRetries: 0,

    prepareHeaders: (headers) => {
      const url = new URL(window.location.href);
      const urlToken = url.searchParams.get("access_token");
      const auth = localStorage.getItem("auth_client") || urlToken;
      if (auth) {
        headers.set("Authorization", `Bearer ${auth}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Main", "MainAdmin", "getNotification", "Schedules"],
  endpoints: (builder) => ({
    // GETS
    getMain: builder.query({
      query: () => ({
        url: "api/client/main",
        method: "GET",
      }),
      providesTags: ["Main"],
    }),

    getSingleClient: builder.query({
      query: (id) => ({
        url: `api/client/${id}/details`,
        method: "GET",
      }),
    }),

    getNotifications: builder.query({
      query: () => ({
        url: "api/client/notifications",
        method: "GET",
      }),
      providesTags: ["getNotification"],
    }),

    getClientCalendar: builder.query({
      query: () => ({
        url: "api/client/schedules",
        method: "GET",
      }),
      providesTags: ["Schedules"],
    }),
    // getSingleClientTransactions: builder.query({
    //   query: (args) => {
    //     return {
    //       url: `api/client/transactions`,
    //       method: "GET",
    //     };
    //   },
    // }),
    getSingleClientTransactions: builder.query({
      query: (args) => {
        const { from, to } = args;
        return {
          url: `api/client/transactions`,
          method: "GET",
          params: {
            from,
            to,
          },
        };
      },
    }),
    getBTC: builder.query({
      query: (id) => ({
        url: `api/client/btc/transfers`,
        method: "GET",
      }),
    }),
    // /client/transaction/{id}'
    getClientTransactionsDetails: builder.query({
      query: (id) => ({
        url: `api/client/transaction/${id}`,
        method: "GET",
      }),
    }),

    getLineChartData: builder.query({
      query: () => ({
        url: `api/client/chart`,
        method: "GET",
      }),
    }),

    // POST

    //Admin

    readNotificationClient: builder.mutation({
      query: ({ notification_id }) => ({
        url: `api/client/notification/read`,
        method: "POST",
        body: {
          notification_id: notification_id,
        },
      }),
      invalidatesTags: ["getNotification"],
    }),

    scheduleCall: builder.mutation({
      query: ({ start_date, title, description }) => ({
        url: `api/client/schedule`,
        body: {
          start_date: start_date,
          title: title,
          description: description,
        },
        method: "POST",
      }),
      invalidatesTags: ["Schedules"],
    }),
    createClientTransaction: builder.mutation({
      query: ({ transferType, data }) => ({
        url: `api/client/outgoing`,
        method: "POST",
        body: {
          bank_type_id: transferType,
          amount: data.amount,
          bank_name: data.bank_name,
          beneficary_address: data.beneficary_address,
          beneficary_country: data.beneficary_country,
          beneficary_name: data.beneficary_name,
          description: data.description,
          execution_date: data.execution_date,
          iban: data.iban,
          priority: data.priority,
          swift_code: data.swift_code,
        },
      }),
      invalidatesTags: ["Main"],
    }),

    createBTC: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/client/btc`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Main"],
    }),

    // PUT

    transferMessage: builder.mutation({
      query: ({ transaction_id, client_response }) => ({
        url: `api/client/transaction/${transaction_id}/response`,
        method: "PUT",
        body: { client_response },
      }),
      invalidatesTags: ["getTransaction"],
    }),
  }),
});

export const {
  useGetMainQuery,
  useLazyGetMainQuery,
  useLazyGetInvoiceListQuery,
  useGetSingleClientQuery,
  useGetNotificationsQuery,
  useGetSingleClientTransactionsQuery,
  useLazyGetSingleClientTransactionsQuery,
  useLazyGetClientTransactionsDetailsQuery,
  useGetBTCQuery,
  useGetLineChartDataQuery,
  useGetClientCalendarQuery,

  // POSTS
  useReadNotificationClientMutation,
  useCreateClientTransactionMutation,
  useScheduleCallMutation,
  useCreateBTCMutation,

  // PUT

  useUpdateSettleMutation,
  useTransferMessageMutation,
  useUpdateScheduleMutation,
} = clientApi;
