import React from "react";
import Form from "./Form";

const Transfer = ({ setIsHistory }) => {
  return (
    <div>
      {" "}
      <Form setIsHistory={setIsHistory} />
    </div>
  );
};

export default Transfer;
