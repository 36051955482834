import React, { useState, useEffect } from "react";
import "./transfer.scss";
import {
  useCreateClientTransactionMutation,
  useLazyGetMainQuery,
} from "services/clientApi";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

const Form = ({
  transferType,
  setTransferType,
  setTransactionStep,
  goBack,
  setAttentionData,
}) => {
  const [data, setFormData] = useState("");
  const [createTransaction] = useCreateClientTransactionMutation();
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");

  const [getMain] = useLazyGetMainQuery();

  const handleChange = (e) => {
    setFormData({ ...data, [e.target.name]: e.target.value });
  };
  const selectedType = localStorage.getItem("type");

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await createTransaction({ transferType, data });

    if ("error" in result) {
      console.log("errror");
    } else {
      setAttentionData(result.data);
      setTransactionStep("step-3");
      getMain();
      toast.success("Created Succesfully");
    }
  };

  return (
    <div className="w-80 shadow-sm p-3  m-auto bg-white rounded  my-5">
      <div className="d-flex gap-2 " type="button" onClick={goBack}>
        <i className="fa-solid fa-arrow-left mt-1" />

        <p>Back</p>
      </div>
      <div className="text-center ">
        <h4>
          {" "}
          {selectedType === "1"
            ? "Between Accounts"
            : selectedType === "2"
            ? "Other Accounts"
            : selectedType === "3"
            ? "International"
            : ""}
        </h4>
      </div>
      <div className="w-75 m-auto mb-4 text-center">
        <small className="fw-bold my-3 text-primary">
          Please fill in all fields
        </small>
      </div>
      <form className=" w-100 m-auto ">
        <div className="">
          <div className={`${isMobile ? "" : "w-100  d-flex gap-4 mb-3"}`}>
            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <label className="light-grey" htmlFor="exampleInputPassword1">
                Beneficiary Name
              </label>
              <input
                type="text"
                className="form-control "
                style={{ fontWeight: "500" }}
                placeholder="Beneficary Name"
                name="beneficary_name"
                onChange={handleChange}
              />
            </div>
            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <label className="light-grey" htmlFor="exampleInputEmail1">
                Beneficiary Address
              </label>
              <input
                type="text"
                style={{ fontWeight: "500" }}
                className="form-control"
                placeholder="Beneficiary Address"
                name="beneficary_address"
                onChange={handleChange}
              />
            </div>{" "}
          </div>
          <div className={`${isMobile ? "" : "w-100  d-flex gap-4 mb-3"}`}>
            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <label className="light-grey" htmlFor="exampleInputPassword1">
                Beneficiary Country
              </label>
              <input
                type="text"
                style={{ fontWeight: "500" }}
                className="form-control"
                placeholder="Beneficiary Country"
                name="beneficary_country"
                onChange={handleChange}
              />
            </div>
            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <label className="light-grey" htmlFor="exampleInputPassword1">
                Bank Name
              </label>
              <input
                type="text"
                style={{ fontWeight: "500" }}
                className="form-control"
                placeholder="Bank Name"
                name="bank_name"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group mb-3 ">
            <div className={`${isMobile ? "" : "w-100  d-flex gap-4 mb-3"}`}>
              <div
                className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}
              >
                <label className="light-grey" htmlFor="exampleInputPassword1">
                  IBAN
                </label>
                <input
                  type="text"
                  style={{ fontWeight: "500" }}
                  className="form-control"
                  placeholder="Iban"
                  name="iban"
                  onChange={handleChange}
                />
              </div>{" "}
              <div
                className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}
              >
                <label className="light-grey" htmlFor="exampleInputEmail1">
                  BIC/SWIFT
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ fontWeight: "500" }}
                  placeholder="BIC/SWIFT"
                  name="swift_code"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <div
                className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}
              >
                <label className="light-grey" htmlFor="exampleInputPassword1">
                  Amount
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ fontWeight: "500" }}
                  placeholder="Amount"
                  name="amount"
                  onChange={handleChange}
                />
              </div>
              <div
                className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}
              >
                <label className="light-grey" htmlFor="exampleInputPassword1">
                  Execution Date
                </label>
                <input
                  type="datetime-local"
                  className="form-control w-100"
                  style={{ fontWeight: "500" }}
                  name="execution_date"
                  onChange={handleChange}
                />{" "}
              </div>
            </div>
            <div className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}>
              <div
                className={`form-group mb-2 ${isMobile ? "w-100" : "w-50"} `}
              >
                <label className="light-grey" htmlFor="exampleInputEmail1">
                  Description
                </label>
                <textarea
                  type="Description "
                  className="form-control"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-2 d-flex gap-2 mt-4">
                <label className="light-grey" htmlFor="exampleInputPassword1">
                  Priority
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                  name="priority"
                  onChange={(e) =>
                    setFormData({ ...data, priority: e.target.checked })
                  }
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-4">
          {" "}
          <button
            onClick={handleSubmit}
            style={{ borderRadius: "20px" }}
            className={` ${
              isMobile ? "w-100" : "w-25"
            } btn btn-primary shadow-lg wire-frame-send bg-transparent text-dark my-4`}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
