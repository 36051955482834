import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

const customBackOff = async (attempt = 0, maxRetries = 0) => {
  const attempts = Math.min(attempt, maxRetries);

  await new Promise((resolve) => {
    setTimeout(resolve, attempts * 1000);
  });
};

const baseQuery = (baseQueryOptions) => async (args, api, extraOptions) => {
  const result = await fetchBaseQuery(baseQueryOptions)(
    args,
    api,
    extraOptions
  );

  if (result.error) {
    toast.error(result.error.data.error, {
      toastId: result.error.data.error,
    });
  }
  return result;
};

const isLocalHost = !!(
  location.hostname === "localhost" || location.hostname === "127.0.0.1"
);

export const auth = createApi({
  reducerPath: "auth",
  //   baseQuery: fetchBaseQuery({
  //     baseUrl: isLocalHost
  //       ? "http://46.166.174.21:8002"
  //       : "http://finance.stage/backend",
  //   }),
  baseQuery: baseQuery({
    baseUrl: isLocalHost
      ? "http://93.115.31.120:8002"
      : window.location.protocol + "/backend",
    backoff: customBackOff,
    maxRetries: 0,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    // GETS

    // POST
    login: builder.mutation({
      query: ({ email }) => ({
        url: `api/client/opt`,
        method: "POST",
        body: {
          email,
          // password,
        },
      }),
    }),

    password: builder.mutation({
      query: ({ email, password }) => ({
        url: `api/client/login`,
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, usePasswordMutation } = auth;
