import React, { useState, useEffect } from "react";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import Header from "shared/Header/Header.jsx";
import HomeComp from "components/Home/HomeCmp";
import { useSelector } from "react-redux";

const Home = () => {
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };

  return (
    <div className=" d-flex " style={{ overflow: "auto" }}>
      {isMobile ? (
        openMobileSide ? (
          <MobileSide openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar />
      )}
      {/* <Sidebar /> */}
      <div className="w-100 bg-light">
        <Header openSideBar={openSideBar} openMobileSide={openMobileSide} />
        <HomeComp />
      </div>
    </div>
  );
};

export default PrivateRoute(Home);
