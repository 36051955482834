import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "../../../assets/images/logo.png";
import formatNumber from "../../../helpers/NumberFormat";

const stylesTable = StyleSheet.create({
  table: {
    display: "table",
    width: "90%",
    marginTop: "220px",
    marginLeft: "auto",
    marginRight: "auto",
    borderStyle: "solid",
  },
  tableRowHeader: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    border: "solid",
    color: "black",
    fontSize: "12px",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,

    // backgroundColor: "#5c7b9a",
    padding: "1px",
  },
  tableRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    border: "solid",
    color: "black",
    padding: "10px",
    fontSize: "14px",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    border: "solid",
    color: "black",
    fontSize: "12px",
    padding: "10px",
  },
  tableColLast: {
    width: "100%",
    padding: "10px",
    border: "solid",
    color: "black",
    fontSize: "12px",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColFinal: {
    width: "100%",
    borderStyle: "solid",
    border: "solid",
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    padding: "10px",
    marginTop: "30px",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});

const stylesText = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "space-between",
    margin: "30px",
    marginTop: "20px",
  },
  leftView: {
    marginTop: "50px",
  },
  rightView: {
    width: "30%",
  },
  smallContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  image: {
    width: "250px",
  },
  elements: {
    fontSize: "14px",
    marginTop: "12px",
    fontWeight: "800",
  },
  receiver: {
    fontSize: "18px",
    marginTop: "12px",
  },
  meetAll: {
    fontSize: "9px",
    marginLeft: "80px",
    border: "solid",
    marginTop: "-20px",
  },
  title: {
    fontSize: "30px",
    fontWeight: "900",
    marginTop: "50px",
  },
  thankyou: {
    marginTop: "150px",
  },
});

const PdfBit = ({ details }) => (
  <Document>
    <Page size="A4">
      <View style={stylesText.container}>
        <View>
          <Image style={stylesText.image} src={Logo} />
          <Text style={stylesText.meetAll}>Meet All Your Needs</Text>
          <View style={stylesText.leftView}>
            <Text style={stylesText.elements}>Invoice To:</Text>
            <Text style={stylesText.receiver}>{details.to}</Text>
          </View>
        </View>

        <View style={stylesText.rightView}>
          <Text style={stylesText.title}>INVOICE</Text>{" "}
          <View style={stylesText.smallContainer}>
            {" "}
            <Text style={stylesText.elements}>Invoice# </Text>
            <Text style={stylesText.elements}>{details?.id}</Text>
          </View>
          <View style={stylesText.smallContainer}>
            <Text style={stylesText.elements}>Date</Text>
            <Text style={stylesText.elements}>
              {moment(details?.created_at).format("DD/MM/YYYY").split(",")}{" "}
            </Text>
          </View>
        </View>
      </View>
      <View style={stylesTable.table}>
        <View style={stylesTable.tableRowHeader}>
          <Text style={stylesTable.tableColHeader}></Text>
          <Text style={stylesTable.tableColHeader}>Item description</Text>

          <Text style={stylesTable.tableColHeader}>Amount</Text>
        </View>

        <View style={stylesTable.tableRow}>
          <Text style={stylesTable.tableColLast}>
            Transaction ID {details?.id}
          </Text>
          <Text style={stylesTable.tableColLast}>{details.description}</Text>
          <Text style={stylesTable.tableColLast}>
            {formatNumber(details.amount)} Euro
          </Text>
        </View>
        <View style={stylesTable.tableRow}>
          <Text style={stylesTable.tableCol}></Text>
          <Text style={stylesTable.tableCol}></Text>
          <Text style={stylesTable.tableColFinal}>
            Total {formatNumber(details?.amount)} €{" "}
          </Text>
        </View>
        <View style={stylesText.thankyou}>
          <Text>Thank You!</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PdfBit;
