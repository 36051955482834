import React, { useState } from "react";
import "./profile.scss";
import endpoint from "../../utilis.js";

const Documents = ({ documents, main }) => {
  const [openImage, setOpenImage] = useState(false);

  return (
    <div>
      {openImage ? (
        <div className="image-preview">
          <div className="text-end mx-3">
            <i
              onClick={() => setOpenImage(null)}
              type="button"
              className="fa-solid fa-xmark p-2"
              style={{ fontSize: "25px" }}
            />
          </div>
          <div className="m-4">
            <img
              style={{ wisth: "400px", height: "400px" }}
              className="m-4"
              src={endpoint + openImage}
              alt=""
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="m-4">
        <div className="my-5 mx-3">
          <h3>All Documents</h3>
        </div>
        <div className="collapse show" id="collapseExample" show>
          {documents?.map((el, index) => (
            <div
              key={index}
              className="card card-body d-flex justify-content-start gap-3"
            >
              <img
                style={{ width: "20%" }}
                src={endpoint + el.attachment}
              ></img>
              <span className="w-20 fw-bold text-center shadow py-2">
                {el.type_id === 1
                  ? "ID"
                  : el.type_id === 2
                  ? "Payment Proof"
                  : el.type_id === 3
                  ? "Utility Bill"
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Documents;
