import React, { useState, useEffect } from "react";

import Logo from "../../assets/images/logo.png";
import WhiteLogo from "../../assets/images/white-logo.png";
import "./loginV2.scss";
import { useSelector } from "react-redux";

const LoginWelcome = () => {
  const [isMobile, setIsMobile] = useState("");

  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  useEffect(() => {
    if (screenWidth === "md" || screenWidth === "sm" || screenWidth === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);
  return (
    <div
      className={` ${
        isMobile ? "w-100 border" : "w-40"
      }  left-side-container-blue`}
    >
      <div className="content  ">
        <img
          style={{ width: ` ${isMobile ? "300px" : "500px"}` }}
          src={WhiteLogo}
        />
      </div>
      <div className="left-side"></div>
    </div>
  );
};

export default LoginWelcome;
