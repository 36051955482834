import React from "react";

import "./investcomp.scss";

const News = () => {
  return (
    <div>
      <div className="text-center w-95 m-auto">
        <h3>Online investing with UniBank</h3>
        <p>
          Whether you’d like to invest online yourself, online with advice or
          have your portfolio managed for you, there’s an option for you to help
          you make progress toward your investing goals. Platforms that meet
          your evolving needs – so your strategy can grow with you Access to
          world-class research, tools, accounts and investments In-branch
          support if and when you need it – so you’re never investing alone
        </p>
      </div>
      <div className="w-95 m-auto">
        <div className="row gap-3">
          <div className="col ">
            <div className="bg-white ">
              <div className="invest-header text-center pt-4 ">
                <i
                  style={{ fontSize: "90px" }}
                  className="text-primary fa-solid fa-money-bill-trend-up"
                />
                <p className="mt-3">Unibank Self-Directed</p>
                <h5>Trade Online</h5>
              </div>
              <div className="invest-body  position-relative text-center pt-4">
                <div className="custom-round"></div>
                <div className="short-body mt-5">
                  <div className="mt-3 d-flex gap-1 justify-content-center">
                    <p className="fw-bold">€ 0</p> minimum account size
                    <p className="text-primary">†</p>
                  </div>
                  <div className=" d-flex gap-1 justify-content-center">
                    <p className="fw-bold">€ 0 per trade</p> on 80+ ETF's
                    <p className="text-primary">*</p>
                  </div>
                </div>
              </div>
              <div className="invest-footer  text-center pt-4">
                <div className="short-body mt-5">
                  <div className="body-wrapper">
                    <div className="mt-3 d-flex justify-content-between gap-2 mx-4 text-center ">
                      <div className=" w-10 ">
                        <i
                          style={{ fontSize: "25px" }}
                          className=" text-primary fa-regular fa-user mt-1"
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>A great choice if:</strong> You want to be in
                        control of the entire investment process, from research
                        and trading to tracking your portfolio’s progress.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10 ">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-money-bill"
                        />
                      </div>
                      <div className="text-start w-80">
                        <strong>Invest in:</strong> Stocks, bonds, mutual funds,
                        ETFs, GICs, optionsStocks, bonds, mutual funds, Exchange
                        traded funds, Guaranteed Investment Certificates,
                        options.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-euro-sign "
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>Pricing:</strong> €9.95 per trade and €0 per
                        trade on select ETFs.
                      </div>
                    </div>
                  </div>
                  <div className="my-4 mx-3 ">
                    <button className="btn w-100 text-primary border-0 border-bottom  text bg-transparent contact btn-lg my-3">
                      {" "}
                      Feel free to contact us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col ">
            {" "}
            <div className="bg-white ">
              <div className="invest-header text-center pt-4 ">
                <i
                  style={{ fontSize: "90px" }}
                  className="text-primary fa-solid fa-headset"
                />

                <p className="mt-3">Unibank Advice -Direct</p>
                <h5>Trading online with expert advice</h5>
              </div>
              <div className="invest-body  position-relative text-center pt-4">
                <div className="custom-round"></div>
                <div className="short-body mt-5">
                  <div className="mt-3 d-flex gap-1 justify-content-center">
                    <p className="fw-bold">€ 10,000</p> minimum account size
                    <p className="text-primary">†</p>
                  </div>
                  <div className=" d-flex gap-1 justify-content-center">
                    <p className="fw-bold">Up to 185*</p> trades included
                    annually
                    <p className="text-primary">*</p>
                  </div>
                </div>
              </div>
              <div className="invest-footer  text-center pt-4">
                <div className="short-body mt-5">
                  <div className="body-wrapper">
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className=" text-primary fa-regular fa-user mt-1 "
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>A great choice if:</strong> You want to trade
                        yourself, with advisor support and built-in advice
                        backed by expert analysis to help you decide what to do.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-money-bill"
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>Invest in:</strong> Stocks, bonds, mutual funds,
                        ETFs, GICs, optionsStocks, bonds, mutual funds, Exchange
                        traded funds, Guaranteed Investment Certificates.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-euro-sign "
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>Pricing:</strong> Sliding scale based on
                        portfolio size: from €75 up to €3,750 annually.
                      </div>
                    </div>
                  </div>

                  <div className="my-4 mx-3 ">
                    <button className="btn w-100 text-primary  border-0 border-bottom  text bg-transparent contact btn-lg my-3">
                      {" "}
                      Feel free to contact us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col ">
            {" "}
            <div className="bg-white ">
              <div className="invest-header text-center pt-4 ">
                <i
                  style={{ fontSize: "90px" }}
                  className="text-primary fa-solid fa-hand-holding-dollar"
                />

                <p className="mt-3">Unibank SmartFolio</p>
                <h5>Let us invest for you</h5>
              </div>
              <div className="invest-body  position-relative text-center pt-4">
                <div className="custom-round"></div>
                <div className="short-body mt-5">
                  <div className="mt-3 d-flex gap-1 justify-content-center">
                    <p className="fw-bold">€ 1,000</p> minimum account size
                    <p className="text-primary">†</p>
                  </div>
                </div>
              </div>
              <div className="invest-footer  text-center pt-4">
                <div className="short-body mt-5">
                  <div className="body-wrapper">
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className=" text-primary fa-regular fa-user mt-1 "
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>A great choice if:</strong> You want low fees
                        and a professionally managed portfolio that you can
                        access online anytime.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-money-bill"
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>Invest in:</strong> ETFsExchange traded funds.
                      </div>
                    </div>
                    <div className="mt-3 d-flex gap-2 mx-4 text-center">
                      <div className="w-10">
                        <i
                          style={{ fontSize: "25px" }}
                          className="text-primary fa-solid fa-euro-sign "
                        />
                      </div>
                      <div className="text-start w-95">
                        <strong>Pricing:</strong> Sliding scale based on
                        portfolio size: 0.7% - 0.4%.
                      </div>
                    </div>
                  </div>

                  <div className="my-4 mx-3 ">
                    <button className="btn w-100 text-primary  border-0 border-bottom  text bg-transparent contact btn-lg my-3">
                      {" "}
                      Feel free to contact us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
