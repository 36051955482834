import React, { useState, useEffect } from "react";
import "./loginV2.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePasswordMutation } from "services/auth";
import Logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";

const PasswordV2 = () => {
  const [formData, setFormData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const email = localStorage.getItem("email");
  const [isMobile, setIsMobile] = useState("");
  const [submitPassowrd] = usePasswordMutation();
  const navigate = useNavigate();
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (screenWidth === "md" || screenWidth === "sm" || screenWidth === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const handleSubmitPassword = async (event) => {
    event.preventDefault();
    const result = await submitPassowrd({
      email: email,
      password: formData.password,
    });

    if ("error" in result) {
    } else {
      localStorage.setItem("auth_client", result.data.access_token);
      localStorage.setItem("isClient", true);
      navigate("/");
      toast.success("Logged in Succesfully");
    }
  };

  return (
    <div
      className={` ${
        isMobile
          ? "login-container h-100"
          : "login-container d-flex m-auto h-100"
      }`}
    >
      <div
        className={` ${
          isMobile ? "w-100" : "w-40"
        } border left-side-container-password bg-light`}
      >
        <div>{isMobile ? <img src={Logo} /> : ""}</div>
        <div className="left-side">
          <form className="w-85 m-auto mt-5 ">
            {" "}
            <small className="text-grey">
              We have send the OTP to <strong>{email}</strong>
            </small>{" "}
            <div>
              <small className="text-danger">Please Check Your Email</small>
            </div>
            <div className="form-group text-start mt-3">
              <label className="fw-bold">Code</label>
              <div className="input-group" id="show_hide_password">
                <input
                  className="form-control border"
                  type={showPassword ? "" : "password"}
                  name="password"
                  onChange={handleChange}
                />
                <div className="input-group-addon border-bottom">
                  {showPassword ? (
                    <p
                      onClick={() => setShowPassword(false)}
                      className=" show m-2 fw-bold"
                    >
                      Hide
                    </p>
                  ) : (
                    <p
                      onClick={() => setShowPassword(true)}
                      className="show m-2 fw-bold"
                    >
                      Show
                    </p>
                  )}{" "}
                </div>
              </div>
            </div>
            <div>
              {" "}
              <small>
                If you didn't receive the code!{" "}
                <strong
                  type="button"
                  onClick={() => navigate("/login")}
                  className="text-danger"
                >
                  Resend
                </strong>
              </small>
            </div>
            <div className="text-end">
              <button
                onClick={handleSubmitPassword}
                type="submit"
                className="btn btn-primary my-5 fw-bold w-25 shadow-lg btn-login"
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      {isMobile ? (
        ""
      ) : (
        <div className="w-60 right-side">
          <div>
            <img style={{ width: "500px" }} src={Logo} />
            {/* <h1 style={{ fontSize: "70px" }} className="text-primary fw-light">
            Welcome to <strong>UniBank</strong>
          </h1>{" "}
          <h4 className="text-primary fw-light ">
            {" "}
            Log in to access your account
          </h4> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordV2;
