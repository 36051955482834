import React, { useState, useEffect } from "react";
import "./transfer.scss";
import Form from "./Form";
import Controller from "./Controller";
import Finish from "./Finish";
import { useGetMainQuery } from "services/clientApi";
import { useSelector } from "react-redux";
import formatNumber from "../../../helpers/NumberFormat";

const Transfer = ({ setIsHistory }) => {
  const [transferType, setTransferType] = useState("");
  const { data: main, isError: error } = useGetMainQuery();
  const [transactionStep, setTransactionStep] = useState("");
  const [isMobile, setIsMobile] = useState("");
  const [attentionData, setAttentionData] = useState("");

  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const handleChange = (e) => {
    setTransactionStep("step-2");
    setTransferType(e.target.value);
    localStorage.setItem("type", e.target.value);
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  function goBack() {
    setTransactionStep("");
    setTransferType("");
  }

  return (
    <div className="border h-100">
      <div className="text-end w-100">
        {" "}
        {isMobile ? (
          <button
            onClick={() => setIsHistory(true)}
            className={` btn btn-primary btn-sm  mt-2

                         "bg-light-blue text-white "
        
                     border-0 rounded mx-2 fw-bold`}
          >
            History
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="d-flex justify-content-between mx-2 my-5 border-bottom">
        <h4
          style={{ fontSize: `${isMobile ? "15px" : ""}` }}
          className="text-primary"
        >
          Wire Transfer
        </h4>
        <div
          className={` ${
            main?.user?.detail?.allow_transfer === false
              ? "light-grey"
              : "text-green"
          }  d-flex gap-2 `}
        >
          <h4>Balance:</h4>
          <h4>{formatNumber(main?.user?.balance)} €</h4>
        </div>
      </div>
      {screenWidth === "xs" ? (
        ""
      ) : screenWidth === "sm" ? (
        ""
      ) : screenWidth === "md" ? (
        ""
      ) : (
        <Controller
          hasAccess={main?.user?.detail?.allow_transfer === false}
          transactionStep={transactionStep}
        />
      )}

      {transactionStep === "step-2" ? (
        <div className="">
          <Form
            setAttentionData={setAttentionData}
            goBack={goBack}
            setTransferType={setTransferType}
            setTransactionStep={setTransactionStep}
            transferType={transferType}
          />
        </div>
      ) : transactionStep === "step-3" ? (
        <Finish attentionData={attentionData} />
      ) : (
        <div className=" py-3 my-5  w-100">
          <div className="text-center mb-2">
            {main?.user?.detail?.allow_transfer === false ? (
              <h5 className="text-primary fw-bold">
                Transfer Restrictions! Our Team will Reach Out Shortly.
              </h5>
            ) : (
              <h5 className="">Please Choose Transfer Type</h5>
            )}
          </div>
          <div className="pt-2">
            <div className={`${isMobile ? "w-75 m-auto" : "w-30 m-auto"}`}>
              <div className="shadow custom-rounded transfer-type-button p-3  my-3">
                <div className="form-check">
                  {main?.user?.detail?.allow_transfer === false ? (
                    ""
                  ) : (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="bank_type_id"
                      value={1}
                      id="flexRadioDefault1"
                      onChange={handleChange}
                    />
                  )}
                  <label
                    className="form-check-label fw-bold"
                    htmlFor="flexRadioDefault1"
                  >
                    Between Accounts
                  </label>
                </div>
              </div>
              <div className="shadow custom-rounded transfer-type-button p-3 my-3">
                <div className="form-check ">
                  {main?.user?.detail?.allow_transfer === false ? (
                    ""
                  ) : (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="bank_type_id"
                      value={2}
                      id="flexRadioDefault2"
                      onChange={handleChange}
                    />
                  )}

                  <label
                    className="form-check-label fw-bold"
                    htmlFor="flexRadioDefault2"
                  >
                    Other Accounts
                  </label>
                </div>
              </div>
              <div className="shadow transfer-type-button custom-rounded p-3">
                <div className="form-check">
                  <label
                    className="form-check-label fw-bold"
                    htmlFor="flexRadioDefault3"
                  >
                    International
                  </label>
                  {main?.user?.detail?.allow_transfer === false ? (
                    ""
                  ) : (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="bank_type_id"
                      value={3}
                      id="flexRadioDefault3"
                      onChange={handleChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Transfer;
