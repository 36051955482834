import React from "react";
import { createPortal } from "react-dom";

const ModalRoot = (props) => {
  const modalRoot = document.getElementById("modal");
  return createPortal(props.children, modalRoot);
};

function ModalV2({
  open,
  handleCancel,
  children,
  title = null,
  footerButtons,
}) {
  if (!open) return null;

  return (
    <>
      <ModalRoot>
        <div
          className={"modal fade " + (open ? "show" : "")}
          style={{ display: "block ", borderRadius: "400px !important" }}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ borderRadius: "400px !important" }}
          >
            <div onClick={(e) => e.stopPropagation()} className="modal-content">
              <div className="modal-header bg-custom-modal justify-content-end border-bottom-0 py-1">
                <button
                  onClick={() => handleCancel()}
                  type="button"
                  className="close bg-transparent border-0  fs-2"
                  aria-label="Close"
                >
                  <span
                    className="fw-bold bg-custom-modal text-white"
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="text-center">
                {title && <h5 className="modal-title fw-bold">{title}</h5>}
              </div>
              <div className="modal-body">{children}</div>
              <div className="modal-footer py-1 border-0 pb-4 ">
                {/* <button
                  onClick={() => dispatch(openModal())}
                  type="button"
                  className="btn btn-secondary"
                >
                  Close
                </button> */}
                {footerButtons?.map((button) => (
                  <button
                    key={button.title}
                    onClick={button.func}
                    type="button"
                    className={`btn ${
                      button?.title === "Cancel"
                        ? "bg-transparent"
                        : "btn-info text-white"
                    } `}
                  >
                    {button.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ModalRoot>
    </>
  );
}

export default ModalV2;
