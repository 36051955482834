import React from "react";
import Table from "./Table";
import { useGetBTCQuery } from "services/clientApi";

const History = ({ setIsHistory }) => {
  const { data: bitcoin } = useGetBTCQuery();
  return (
    <div className="w-95 m-auto btc-wrapper">
      <Table setIsHistory={setIsHistory} transactions={bitcoin} />
    </div>
  );
};

export default History;
