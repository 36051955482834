import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import "./client.scss";
import { useUploadImageMutation } from "services/mainApi";
import { toast } from "react-toastify";

const UploadDocument = ({ id, main }) => {
  const [handleDocument, setHandleDocument] = useState("");

  const [uploadDocuments] = useUploadImageMutation();

  const submitImage = async (event) => {
    event.preventDefault();
    const result = await uploadDocuments({
      client_id: id,
      attachment: handleDocument?.file.split(",")[1],
      type_id: handleDocument?.type_id,
      description: handleDocument?.description,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Uploaded Succesfully");
    }
  };
  const handleChangeImage = (base64) => {
    setHandleDocument({
      ...handleDocument,
      file: base64,
    });
  };
  return (
    <div className="w-95 m-auto">
      <div className="mt-5">
        {/* <p>
          <a
            class="btn btn-primary"
            data-toggle="collapse"
            show
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Upload Documents
          </a>
        </p> */}
        <div className=" shadow-sm p-3 bg-white rounded " id="collapseExample">
          <div className="d-flex w-100 my-4  ">
            <div className=" upload-container m-auto upload-file-input  position-relative">
              <div>
                {" "}
                <i className=" text-primary upload-icon fa-solid fa-upload" />
              </div>

              <FileBase64 onDone={({ base64 }) => handleChangeImage(base64)} />
              {/* <div>
                <h4>Drag and Drop file or</h4>
              </div> */}
              {handleDocument?.file ? (
                <div className="">
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={handleDocument?.file}
                  />
                </div>
              ) : (
                <div className="mt-3">
                  <h3>Drag and drop files</h3>
                  <p>or</p>
                  <button className="btn btn-primary w-50">Browse</button>
                </div>
              )}
            </div>
            <div className="w-50 border shadow-sm p-3 bg-light rounded">
              <div className="text-center">
                <div className="dropdown ">
                  <button
                    className="btn w-50 btn-primary bg-transparent text-dark dropdown-toggle"
                    style={{ overflow: "hidden" }}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {handleDocument.type_id
                      ? handleDocument.name
                      : "Select Document Type "}
                  </button>
                  <div
                    className="dropdown-menu w-50"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {main?.document_types?.map((el, index) => (
                      <a
                        onClick={() =>
                          setHandleDocument({
                            ...handleDocument,
                            type_id: el.id,
                            name: el.name,
                          })
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {el.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style={{ borderTop: "solid !important" }}
                  placeholder="Add Description"
                  onChange={(e) =>
                    setHandleDocument({
                      ...handleDocument,
                      description: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="w-100 text-center mt-4">
                <button onClick={submitImage} className="btn btn-primary w-50">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
