import React from "react";
import Modal from "shared/Modal/Modal";
import formatNumber from "../..//../../helpers/NumberFormat";

import moment from "moment";
const Details = ({ details, openModal, triggerModal }) => {
  return (
    <div>
      {" "}
      <Modal open={openModal} handleCancel={triggerModal}>
        <div className="w-100 bg-primary d-flex justify-content-between text-white">
          {" "}
          <h4 className="p-4">Transaction {details?.id} Details</h4>
          <div className="p-4">
            {" "}
            <h5>{moment(details?.created_at).format("DD/MM/YYYY")}</h5>
          </div>
        </div>
        <div className="mt-5 m-auto w-75 text-center  shadow-sm p-4">
          {details?.type === "out" ? (
            <div>
              <div className="d-flex  gap-3  w-100 ">
                <div className="w-50 text-start  ">
                  <h5 className="text-dark">Requested Amount:</h5>
                </div>
                <div className="w-50 text-start text-dark">
                  <h6>{formatNumber(details.amount)} €</h6>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-3  w-100  border-bottom ">
                <>
                  {" "}
                  <div className="w-50 text-start ">
                    <h5 className="text-dark">
                      Transaction Fee ({details?.outgoing_fee}%):
                    </h5>
                  </div>
                  <div className="w-50 text-start text-dark">
                    <h6 className=" w-25  ">
                      {(details?.amount * details?.outgoing_fee) / 100} €{" "}
                    </h6>
                  </div>
                </>
              </div>
              <div className="gap-3  mt-4">
                <div className="w-50 text-start ">
                  <h6 className="text-dark fw-bold">Notes</h6>
                </div>
                <div className="w-50 text-start mt-1">
                  <h6>{details?.description} </h6>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center gap-3">
                <div className="w-50 text-start ">
                  <h5 className="text-green">Credit:</h5>
                </div>
                <div className="w-50 text-start ">
                  <h5>{formatNumber(details?.amount)} €</h5>
                </div>
              </div>
              <div className="gap-3  mt-4">
                <div className="w-50 text-start ">
                  <h6 className="text-dark fw-bold">Notes</h6>
                </div>
                <div className="w-50 text-start mt-1">
                  <h6>{details?.description} </h6>
                </div>
              </div>
            </>
          )}

          {/* <div className="d-flex justify-content-center gap-3">
            <div className="w-50 text-start ">
              <h5 className="text-grey">Value Date:</h5>
            </div>
            <div className="w-50 text-start">
              <h5>{details?.value_date}</h5>{" "}
            </div>
          </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default Details;
