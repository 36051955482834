import React from "react";
import Modal from "shared/Modal/Modal";
const Details = ({ details, openModal, triggerModal }) => {
  return (
    <div>
      {" "}
      <Modal open={openModal} handleCancel={triggerModal}>
        <div>
          {" "}
          <h4>Transactions Details</h4>
        </div>
        <div className="mt-5 m-auto w-75 text-center border shadow-sm p-4">
          <div className="d-flex  justify-content-center gap-3  w-100 ">
            <div className="w-25 ">
              <h5 className="text-grey">Debit:</h5>
            </div>
            <div className="w-25 ">
              <h5>{details?.debit} €</h5>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <div className="w-25">
              <h5 className="text-grey">Credit:</h5>
            </div>
            <div className="w-25">
              <h5>{details?.credit} €</h5>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <div className="w-25">
              <h5 className="text-grey"> Posting Date:</h5>
            </div>
            <div className="w-25">
              {" "}
              <h5>{details?.posting_date}</h5>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <div className="w-25">
              <h5 className="text-grey">Value Date:</h5>
            </div>
            <div className="w-25">
              <h5>{details?.value_date}</h5>{" "}
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <div className="w-25">
              <h5 className="text-grey">Description:</h5>
            </div>
            <div className="w-25">
              <h5>{details?.description} </h5>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Details;
