import React, { useState, useEffect } from "react";
import "./loginV2.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation, usePasswordMutation } from "services/auth";
import { useSelector } from "react-redux";
import WhiteLogo from "../../assets/images/white-logo.png";

import Logo from "../../assets/images/logo.png";
import LoginWelcome from "./LoginWelcome";

const Login2 = () => {
  const [formData, setFormData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [login] = useLoginMutation();
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (screenWidth === "md" || screenWidth === "sm" || screenWidth === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitOtp = async (event) => {
    event.preventDefault();
    if (formData.email) {
      setLoading(true);
    }
    const result = await login({
      email: formData.email,
    });
    if (formData.email) {
      navigate("/step-2");
      localStorage.setItem("email", formData.email);
    }

    if ("error" in result) {
    } else {
      // localStorage.setItem("auth", result.data.access_token);
      // localStorage.setItem("role", result.data.data.user.role.name);
      // navigate("/");
      // toast.success("Logged in Succesfully");
      setLoading(false);
      localStorage.setItem("email", formData.email);
      toast.success("Please check your email");
      navigate("/step-2");
    }
  };

  return (
    <div
      className={` ${
        isMobile
          ? "login-container h-100 position-relative"
          : "login-container d-flex  h-100 border position-relative"
      }`}
    >
      <LoginWelcome />
      <div
        className={` ${
          isMobile ? "w-100" : "w-40"
        } left-side-container bg-light`}
      >
        <div>{isMobile ? <img src={Logo} /> : ""}</div>
        <div className="left-side">
          <form className="w-80 m-auto mt-5">
            <div className="form-group text-start mt-5">
              <label className="fw-bold" for="userId">
                Email
              </label>
              <input
                type="email"
                className="form-control border"
                name="email"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handleChange}
                placeholder="Enter Email"
              />
            </div>

            <div className="text-end">
              <button
                onClick={handleSubmitOtp}
                type="submit"
                className="btn btn-primary my-5 fw-bold w-25 shadow-lg btn-login"
                disabled={loading ? "disabled" : ""}
              >
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only text-primary">Loading...</span>
                  </div>
                ) : (
                  "Send"
                )}{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isMobile ? (
        ""
      ) : (
        <div className="w-60 right-side">
          <div>
            <img style={{ width: "500px" }} src={Logo} />
            {/* <h1 style={{ fontSize: "70px" }} className="text-primary fw-light">
            Welcome to <strong>UniBank</strong>
          </h1>{" "}
          <h4 className="text-primary fw-light ">
            {" "}
            Log in to access your account
          </h4> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login2;
