import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as Page from "pages";
import * as AdminPage from "adminpages";

const router = createBrowserRouter([
  //end user
  {
    path: "/login",
    element: <Page.LoginV2 />,
  },

  {
    path: "/step-2",
    element: <Page.PasswordV2 />,
  },
  {
    path: "/schedule",
    element: <Page.Scheduler />,
  },
  {
    path: "/",
    element: <Page.Home />,
  },
  {
    path: "/transactions",
    element: <Page.Transactions />,
  },
  {
    path: "/exchange",
    element: <Page.Bitcoin />,
  },
  {
    path: "/transactions/b2c",
    element: <Page.Bitcoin />,
  },
  {
    path: "/profile",
    element: <Page.Profile />,
  },
  {
    path: "/investments",
    element: <Page.Investments />,
  },

  //admin
  {
    path: "/admin/Home",
    element: <AdminPage.AdminHome />,
  },
  {
    path: "/admin/Transactions",
    element: <AdminPage.LatestTransctions />,
  },
  {
    path: "/admin/Transactions/:id",
    element: <AdminPage.AdminTransactions />,
  },
  {
    path: "/admin/Profile",
    element: <AdminPage.AdminProfile />,
  },
  {
    path: "/admin/create-client",
    element: <AdminPage.CreateClient />,
  },
  {
    path: "/admin/update-client/:id",
    element: <AdminPage.UpdateClient />,
  },
  {
    path: "/settings",
    element: <AdminPage.Settings />,
  },
  {
    path: "/calendar",
    element: <AdminPage.Calendar />,
  },
]);

export default router;
