import React, { useState } from "react";
import Table from "./Table";
import Modal from "shared/Modal/Modal";
import "./table.scss";
import { useGetTransactionsAdminQuery } from "services/mainApi";
import { useParams } from "react-router-dom";

const History = () => {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const triggerModal = () => {
    setOpenModal(!openModal);
  };
  const { data: transactions } = useGetTransactionsAdminQuery(id);

  return (
    <div className="history-wrpper">
      <Modal open={openModal} handleCancel={triggerModal}>
        <div className="w-100 m-auto text-center">
          <h4>Filter By Date</h4>
        </div>
        <form className="mt-4 w-75 text-center m-auto d-flex justify-content-between ">
          {" "}
          <div className="w-100">
            <label className="fw-bold"> From date</label>
            <input
              type="date"
              className="form-control border text-muted w-100"
              placeholder="Select Date"
              name="date"
            />
          </div>
          <div className="w-100">
            <label className="fw-bold"> To date</label>
            <input
              type="date"
              className="form-control border text-muted w-100"
              placeholder="Select Date"
              name="date"
            />
          </div>
        </form>
        <div className="mt-5 m-auto w-100 text-end">
          <button className="btn btn-primary mx-4">Submit</button>
        </div>
      </Modal>
      <div className="d-flex w-100 ">
        <div className="w-100 d-flex justify-content-between ">
          <div className="form-group text-start mt-3  mx-4 ">
            <div className="input-group">
              <input
                className="form-control form-control-sm"
                placeholder="Search here..."
              />
              <div className="input-group-addon border-bottom">
                <i
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  className="fa-solid fa-magnifying-glass mt-2 mx-2"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div className="W- 100 mt-3 mx-5 d-flex justify-content-between gap-4">
          <div className=" w-25 d-flex gap-2">
            <p className="text-grey mt-1">Type</p>
            <div className="w-25">
              {" "}
              <button
                className="btn btn-secondary dropdown-toggle btn-sm"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" href="#">
                  Incoming
                </a>
                <a className="dropdown-item" href="#">
                  Outgoing
                </a>
              </div>
            </div>
          </div>
          <div
            onClick={triggerModal}
            className="mx-1"
            style={{ cursor: "pointer" }}
          >
            <i className="fa-solid fa-filter" style={{ fontSize: "30px" }}></i>
          </div>
        </div>
      </div>
      <Table transactions={transactions} />
    </div>
  );
};

export default History;
