import React from "react";

const Loading = ({ style }) => {
  return (
    <div
      style={style}
      className="spinner-border text-primary"
      role="status"
    ></div>
  );
};

export default Loading;
