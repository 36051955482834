import React from "react";
import "./contactUs.scss";
import Trade from "assets/images/trade-online.jpg";
import Expert from "assets/images/expert.jpg";
import Invest from "assets/images/invest-in-you.jpg";
import { useNavigate } from "react-router-dom";

const ShortInvestments2 = () => {
  const navigate = useNavigate();
  return (
    <div className="w-100 shadow-sm short-investments rounded">
      <div className="p-4 d-flex justify-content-between">
        <h5>Investments</h5>
        <p
          onClick={() => navigate("/investments")}
          type="button"
          className="text-grey "
        >
          Show More
        </p>
      </div>
      <div className="border-bottom w-95 m-auto ">
        <div className="d-flex">
          <img className="investment-photos mt-4" src={Trade} />
          <div className="container mx-2 w-75">
            <div className="row">
              <div className="col my-2">
                {" "}
                <strong className="mx-1 fs-5 ">Trade Online</strong>
              </div>
            </div>
            <div className="row">
              <div className="col text-grey">
                <p>
                  {" "}
                  A great choice if: You want to be in control of the entire
                  investment process, from research and trading to tracking your
                  portfolio’s progress.
                </p>
              </div>
            </div>
          </div>
          <div className="w-25 mt-4 text-grey text-center">Unibank</div>
        </div>
      </div>
      <div className="border-bottom w-95 m-auto ">
        <div className="d-flex">
          <img className="investment-photos mt-4" src={Expert} />
          <div className="container mx-2 w-75">
            <div className="row">
              <div className="col my-2">
                {" "}
                <strong className="mx-1 w-100 fs-5 ">
                  Trading online with expert advice
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col text-grey">
                <p>
                  {" "}
                  A great choice if: You want to trade yourself, with advisor
                  support and built-in advice backed by expert analysis to help
                  you decide what to do.
                </p>
              </div>
            </div>
          </div>
          <div className="w-25 mt-4 text-grey text-center">Unibank</div>
        </div>
      </div>
      <div className="border-bottom w-95 m-auto mb-2 ">
        <div className="d-flex">
          <img className="investment-photos mt-4" src={Invest} />
          <div className="container mx-2 w-75">
            <div className="row">
              <div className="col my-2">
                {" "}
                <strong className="mx-1 fs-5">Let us invest for you</strong>
              </div>
            </div>
            <div className="row">
              <div className="col text-grey">
                <p>
                  {" "}
                  A great choice if: You want low fees and a professionally
                  managed portfolio that you can access online anytime.
                </p>
              </div>
            </div>
          </div>
          <div className="w-25 mt-4 text-grey text-center">Unibank</div>
        </div>
      </div>
    </div>
  );
};

export default ShortInvestments2;
