import React, { useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation, usePasswordMutation } from "services/auth";
import Logo from "../../assets/images/logo.png";

const Login = () => {
  const [formData, setFormData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [login] = useLoginMutation();

  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitOtp = async (event) => {
    event.preventDefault();
    if (formData.email) {
      setLoading(true);
    }
    const result = await login({
      email: formData.email,
    });
    navigate("/step-2");
    localStorage.setItem("email", formData.email);
    if ("error" in result) {
    } else {
      // localStorage.setItem("auth", result.data.access_token);
      // localStorage.setItem("role", result.data.data.user.role.name);
      // navigate("/");
      // toast.success("Logged in Succesfully");
      setLoading(false);
      localStorage.setItem("email", formData.email);
      toast.success("Please check your email");
      navigate("/step-2");
    }
  };

  return (
    <div className=" wrapp  ">
      <div className=" content text-center   border-0 bg-white shadow-sm">
        {/* <h1 className="pt-3">Welcome To</h1> */}
        <img src={Logo} alt="" />
        <h3>Welcome</h3>
        <form className="w-50 m-auto mt-5 ">
          <p>Enter your email to get the password and access the account. </p>
          <small className="text-grey">
            We will send you one time password (OTP)
          </small>
          <div className="form-group text-start mt-5">
            <label className="fw-bold" for="userId">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={handleChange}
              placeholder="Enter Email"
            />
          </div>

          {/* <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <div className="input-group input-group-sm mt-1">
              <label
                className="checkbox-inline"
                aria-describedby="ProcessingConsultantYN"
                id="lbProcessingConsultant"
                for="ProcessingConsultantYN"
              >
                Remember Me
              </label>
            </div>
          </div> */}
          <button
            onClick={handleSubmitOtp}
            type="submit"
            className="btn btn-primary my-5 fw-bold w-25 shadow-lg"
            disabled={loading ? "disabled" : ""}
          >
            {loading ? (
              <div class="spinner-border" role="status">
                <span class="sr-only text-primary">Loading...</span>
              </div>
            ) : (
              "Send"
            )}{" "}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
