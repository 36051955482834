import React, { useState, useEffect } from "react";
import "./home.scss";
import { useSelector } from "react-redux";
import { useGetLineChartDataQuery } from "services/clientApi";
import { useNavigate } from "react-router-dom";
import Master from "../../assets/images/master.png";
import Visa from "../../assets/images/visa.png";
import formatNumber from "../../helpers/NumberFormat";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import { faker } from "@faker-js/faker";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const decimals = 3;
const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return tooltipItem.yLabel;
      },
    },
  },
  title: {
    display: true,
    fontColor: "blue",
    text: "Custom Chart Title",
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: "#e4e4e4",
        fontWeight: "100px",
        drawBorder: false,
      },
      gridLines: {
        drawBorder: false,
      },
      border: {
        dash: [3, 6],
      },
      // ticks: { display: false },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const MainInfo = ({ main }) => {
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const {
    data: lineChartData,
    isLoading: loading,
    isError: error,
  } = useGetLineChartDataQuery();
  const [isMobile, setIsMobile] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const data = {
    labels: [],
    datasets: [
      {
        label: "Value",
        fill: true,
        data: lineChartData?.statuses,
        pointStrokeColor: "rgb(31, 80, 170)",

        pointColor: "rgb(31, 80, 170)",
        strokeColor: "rgb(31, 80, 170)",
        borderColor: "rgb(31, 80, 170)",
        backgroundColor: "#deeaff",
        lineTension: 0.9,
      },
    ],
  };

  return (
    <div className="w-95 m-auto main">
      <div className=" ">
        <div className="">
          <div
            className={`w-100 my-2 ${
              isMobile ? "p-3" : "d-flex justify-content-between  gap-4"
            }`}
          >
            {" "}
            <div
              className={`${
                isMobile ? "w-100" : "w-50"
              } position-relative   bg-white  shadow-lg p-3  bg-white rounded`}
            >
              <div
                className=" rounded m-auto"
                style={{ width: ` ${isMobile ? "100%" : "95%"}` }}
              >
                <div className="">
                  <div className="my-3">
                    <h3>Summary</h3>
                  </div>
                  <small className="text-grey">Balance</small>
                  <div className="d-flex">
                    <div className="total mt-2 mx-2"></div>
                    <h3>{formatNumber(main?.user?.balance)} €</h3>
                  </div>
                </div>

                <Line redraw={true} options={options} data={data}></Line>
              </div>
              <div className="text-end mt-4">
                <button
                  onClick={() => navigate("/transactions")}
                  style={{ borderRadius: "100px" }}
                  className="btn  more-details bg-primary text-white border-0 shadow-lg fw-bold mx-3 p-2 px-3"
                >
                  More details
                </button>
              </div>
            </div>
            <div
              className={`${
                isMobile ? "w-100 mt-4" : "w-50"
              } border bg-white shadow-lg p-3  bg-white rounded`}
            >
              <div
                className={` ${
                  screenWidth === "xs"
                    ? " text-center"
                    : "d-flex justify-content-between"
                }`}
              >
                <h5 className={`${screenWidth === "xs" ? "" : "fw-bold"}`}>
                  Your Account
                </h5>
                <button className=" fw-bold btn btbn-primary btn-sm shadow-lg custom-rounded bg-transparent border-0">
                  {main ? main.user.detail.account_number : ""}
                </button>
              </div>
              <div
                className={` ${
                  screenWidth === "xs"
                    ? ""
                    : "d-flex justify-content-between mt-4 gap-4"
                }`}
              >
                <div className={` ${screenWidth === "xs" ? "w-100" : "w-50"}`}>
                  <div className="border-bottom">
                    <h3
                      className={` ${screenWidth === "xs" ? "mt-2" : "mb-4"}`}
                    >
                      {main
                        ? main?.user.first_name + " " + main?.user.last_name
                        : ""}
                    </h3>
                  </div>{" "}
                  <div className="d-flex justify-content-between mt-4 ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Card</p>
                    </div>
                    <div className="w-50  d-flex justify-content-start gap-2">
                      <img style={{ width: "40px" }} src={Master} alt="" />
                      <img style={{ width: "40px" }} src={Visa} alt="" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Card type:</p>
                    </div>
                    <div className="w-50 text-start">
                      <p className="text-grey">Debit MasterCard</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Account</p>
                    </div>
                    <div className="w-50 text-start">
                      <p className="text-grey">Personal Account</p>
                      <p className="text-grey fw-bold">
                        {" "}
                        {main?.user?.detail?.account_number || ""}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Card Number</p>
                    </div>
                    <div className="w-50 text-start">
                      <p className="text-grey">**** **** **** ****</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Expiry date:</p>
                    </div>
                    <div className="w-50 text-start">
                      <p className="text-grey">--/--/----</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 text-start">
                      <p className="fw-bold">Status</p>
                    </div>
                    <div className="w-50 text-start">
                      <p className="text-danger">Non Active</p>
                    </div>
                  </div>
                </div>
                <div
                  className={` ${
                    screenWidth === "xs" ? "w-100" : "mt-4 mx-3 w-50"
                  }`}
                >
                  <div className="card-info mt-2 pt-5 shadow-lg">
                    <h5 className="text-white text-center mt-2 ">
                      IBAN: {main?.user.detail.iban}
                    </h5>
                    <div className="mt-3 px-3">
                      <small
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      >
                        Account User
                      </small>
                      <p className="text-white ">
                        {main?.user.first_name + " " + main?.user.last_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mx-3" style={{ marginTop: "13%" }}>
                <button
                  onClick={() => navigate("./profile")}
                  className="btn profile-info bg-primary text-white border-0 fw-bold shadow-lg custom-rounded p-2 px-3"
                >
                  Profile Info
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
