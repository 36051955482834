function formatNumber(number) {
  const converted = Number(number);

  const options = {
    style: "decimal", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return converted.toLocaleString("en-US", options);
}

export default formatNumber;
