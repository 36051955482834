import { configureStore } from "@reduxjs/toolkit";
import { mainApi } from "../services/mainApi";
import { clientApi } from "../services/clientApi";
import { auth } from "../services/auth";

import { setupListeners } from "@reduxjs/toolkit/query";
import logger from "redux-logger";
import commonReducer from "../slices/slices";

const store = configureStore({
  reducer: {
    [auth.reducerPath]: auth.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    common: commonReducer,
  },

  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "production") {
      return getDefaultMiddleware().concat(mainApi.middleware);
    } else {
      return getDefaultMiddleware().concat(
        logger,
        mainApi.middleware,
        auth.middleware,
        clientApi.middleware
      );
      // getDefaultMiddleware().concat(logger, auth.middleware)
    }
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);

export default store;
