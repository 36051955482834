import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

const Controller = ({ hasAccess, transactionStep }) => {
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");

  useEffect(() => {
    if (screenWidth === "md" || screenWidth === "sm" || screenWidth === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  return (
    <div className="w-100">
      <div
        className={` ${
          isMobile
            ? " d-flex justify-content-center gap-2 "
            : "d-flex w-95 m-auto justify-content-between pb-3 border-bottom mt-4"
        }`}
      >
        <div className="d-flex light-grey  text-grey gap-3">
          <i
            className={`fa-solid fa-circle-check fs-3 ${
              hasAccess ? "light-grey" : "text-green"
            }`}
          />{" "}
          <h5 className={` ${hasAccess ? "light-grey" : "text-green"}`}>
            Type
          </h5>
          <i
            style={{ fontSize: "14px" }}
            className={`fa-solid fa-circle mt-2  ${
              hasAccess ? "light-grey" : "text-green"
            }`}
          />
          <i
            style={{ fontSize: "14px" }}
            className={`fa-solid fa-circle mt-2  ${
              hasAccess ? "light-grey" : "text-green"
            }`}
          />
          <i
            style={{ fontSize: "14px" }}
            className={`fa-solid fa-circle mt-2  ${
              hasAccess ? "light-grey" : "text-green"
            }`}
          />
          <i
            style={{ fontSize: "14px" }}
            className={`fa-solid fa-circle mt-2 ${
              hasAccess ? "light-grey" : "text-green"
            } `}
          />
        </div>
        <div
          className={`d-flex gap-3 ${
            transactionStep === "step-2"
              ? "text-green "
              : transactionStep === "step-3"
              ? "text-green"
              : " light-grey"
          }`}
        >
          <i className={`fa-solid fa-circle-check fs-3`} />{" "}
          <h5 className="">Information</h5>
          <i
            style={{ fontSize: "14px" }}
            className="fa-solid fa-circle mt-2 "
          />
          <i
            style={{ fontSize: "14px" }}
            className="fa-solid fa-circle mt-2 "
          />
          <i style={{ fontSize: "14px" }} className="fa-solid fa-circle mt-2" />
          <i style={{ fontSize: "14px" }} className="fa-solid fa-circle mt-2" />
        </div>
        <div
          className={`d-flex    gap-3 ${
            transactionStep === "step-3" ? "text-green " : "   light-grey  "
          }`}
        >
          <i className="fa-solid fa-circle-check fs-3 " />{" "}
          <h5 className="">Finish</h5>
          <i
            style={{ fontSize: "14px" }}
            className="fa-solid fa-circle mt-2 "
          />
          <i
            style={{ fontSize: "14px" }}
            className="fa-solid fa-circle mt-2 "
          />
          <i style={{ fontSize: "14px" }} className="fa-solid fa-circle mt-2" />
          <i style={{ fontSize: "14px" }} className="fa-solid fa-circle mt-2" />
        </div>
      </div>
    </div>
  );
};

export default Controller;
