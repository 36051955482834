import React, { useState } from "react";
import "./transfer.scss";
import { toast } from "react-toastify";
import {
  useCreateTransactionMutation,
  useLazyGetTransactionsAdminQuery,
} from "services/mainApi";
import { useParams } from "react-router-dom";

const Form = ({ setIsHistory }) => {
  const [data, setFormData] = useState("");
  const [createTransaction] = useCreateTransactionMutation();
  const [getTransactionsAdmin] = useLazyGetTransactionsAdminQuery();
  const { id } = useParams();

  const handleChange = (e) => {
    setFormData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await createTransaction({ id, data });

    if ("error" in result) {
      console.log("errror");
    } else {
      getTransactionsAdmin(id);
      setIsHistory(true);
      toast.success("Succesfully");
    }
  };
  return (
    <div className="step-2">
      <div className="w-75 m-auto mb-4">
        <small className="fw-bold my-3">Please fill in all fields</small>
      </div>
      <form className="w-75 m-auto ">
        <div>
          <div className="w-100">
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Beneficiary Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Beneficary Name"
                name="beneficary_name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputEmail1">
                Beneficiary Address
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Beneficiary Address"
                name="beneficary_address"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Beneficiary Country
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Beneficiary Country"
                name="beneficary_country"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Bank Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Bank Name"
                name="bank_name"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                IBAN
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Iban"
                name="iban"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="w-100 ">
            {" "}
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputEmail1">
                BIC/SWIFT
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="BIC/SWIFT"
                name="swift_code"
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Amount"
                name="amount"
                onChange={handleChange}
              />
            </div>
            {/* <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Debit
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Debit"
                name="debit"
                onChange={handleChange}
              />
            </div> */}
            {/* <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Credit
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Credit"
                name="credit"
                onChange={handleChange}
              />
            </div> */}
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Execution Date
              </label>
              <input
                type="datetime-local"
                className="form-control w-100"
                name="execution_date"
                onChange={handleChange}
              />{" "}
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold" htmlFor="exampleInputPassword1">
                Value Date
              </label>
              <input
                type="datetime-local"
                className="form-control w-100"
                name="value_date"
                onChange={handleChange}
              />{" "}
            </div>
            <div className="d-flex justify-content-between gap-4">
              <div className="form-group mb-2 w-100">
                <label className="fw-bold" htmlFor="exampleInputEmail1">
                  Description
                </label>
                <textarea
                  type="Description "
                  className="form-control"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-2 d-flex gap-2 mt-4">
                <label className="fw-bold" htmlFor="exampleInputPassword1">
                  Priority
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault"
                  name="priority"
                  onChange={(e) =>
                    setFormData({ ...data, priority: e.target.checked })
                  }
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-4">
          {" "}
          <button onClick={handleSubmit} className="btn btn-primary">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
