import React, { useState } from "react";
import { toast } from "react-toastify";

import Welcome from "../../Home/Welcome";

import { useCreateClientMutation } from "services/mainApi";

const CreateClient = () => {
  const [data, setFormData] = useState("");
  const [createClient] = useCreateClientMutation();

  const handleChange = (e) => {
    if (
      e.target.name === "send_otp" ||
      e.target.name === "allow_transfer" ||
      e.target.name === "send_welcome_email"
    ) {
      setFormData({ ...data, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await createClient(data);

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Created Succesfully");
      setFormData({
        ...data,
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        country: "",
      });
    }
  };

  //   const handleSubmit = (e) => {
  //     e.prevent.default();
  //     createClient(data);
  //   };
  return (
    <div className="step-2">
      <div className="w-75 m-auto mb-4">
        <small className="fw-bold my-3 text-primary">
          Please fill in all fields
        </small>
      </div>
      <form className="w-75 m-auto ">
        <div>
          <div className="w-100">
            <div className="form-group mb-2">
              <label className="fw-bold">Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Enter Email"
                onChange={handleChange}
                value={data?.email}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold">First Name</label>
              <input
                name="first_name"
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                onChange={handleChange}
                value={data?.first_name}
              />
            </div>

            <div className="form-group mb-2">
              <label className="fw-bold">Last Name</label>
              <input
                name="last_name"
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
                onChange={handleChange}
                value={data?.last_name}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold">IBAN</label>
              <input
                name="iban"
                type="text"
                className="form-control"
                placeholder="Enter IBAN"
                onChange={handleChange}
                value={data?.iban}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold">Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Enter Password"
                onChange={handleChange}
                value={data?.password}
              />
            </div>
            <div className="form-group mb-2">
              <label className="fw-bold">Country</label>
              <input
                type="text"
                name="country"
                className="form-control"
                placeholder="Enter Country"
                onChange={handleChange}
                value={data?.country}
              />
            </div>
            <div className="form-group d-flex  gap-2 my-4">
              <label className="w-5 fw-bold mt-2">Fee</label>
              <input
                type="text"
                name="outgoing_fee"
                className="form-control"
                style={{ width: "10%" }}
                onChange={handleChange}
              />
              <div className="input-group-append">
                <span className="input-group-text">%</span>
              </div>
              <div className="form-group mb-2"></div>
            </div>
          </div>
        </div>
        <div className="form-group mb-2 d-flex gap-2 mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
            name="send_otp"
            onChange={handleChange}
          />{" "}
          <label className="fw-bold">Send OTP Automatically</label>
        </div>
        <div className="form-group mb-2 d-flex gap-2 mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
            name="allow_transfer"
            onChange={handleChange}
          />{" "}
          <label className="fw-bold">Allow Transfer</label>
        </div>
        <div className="form-group mb-2 d-flex gap-2 mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
            name="send_welcome_email"
            onChange={handleChange}
          />{" "}
          <label className="fw-bold">Send Welcome Mail</label>
        </div>

        <div className="w-100 text-center mt-4">
          {" "}
          <button onClick={handleSubmit} className="btn btn-primary">
            Create User
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateClient;
