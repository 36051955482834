import React, { useState, useEffect } from "react";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import BitcoinCmp from "components/Bitcoin/BitcoinCmp.jsx";
import { useSelector } from "react-redux";

const Bitcoin = ({ transferType, setTransferType }) => {
  const [isHistory, setIsHistory] = useState(false);
  const [status, setStatus] = useState("Exchange");
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const openHistory = () => {
    setIsHistory(true);
    setStatus("History");
  };

  const openTransfer = () => {
    setIsHistory(false);
    setStatus("Exchange");
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const headerButtons = [
    { name: "Exchange", func: openTransfer },
    { name: "History", func: openHistory },
  ];

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };
  return (
    <div className="w-100 d-flex ">
      {isMobile ? (
        openMobileSide ? (
          <MobileSide openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar />
      )}
      <div className="w-100 bg-light">
        <Header
          openSideBar={openSideBar}
          openMobileSide={openMobileSide}
          isHistory={isHistory}
          headerButtons={headerButtons}
          status={status}
          headerName={"Exchange"}
        />
        <div className="w-95 m-auto bg-white p-4">
          <div className="d-flex justify-content-start gap-5 w-100 ">
            <span
              className={`${
                transferType === "btc" ? "border-bottom-active" : ""
              }`}
              type="button"
            >
              {" "}
              <h6 onClick={() => setTransferType("btc")}>Exchange</h6>
            </span>
          </div>
          <div className="border">
            <BitcoinCmp setIsHistory={setIsHistory} isHistory={isHistory} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute(Bitcoin);
