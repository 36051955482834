import React, { useState } from "react";
import "./transfer.scss";
import Form from "./Form";

const Transfer = ({ setIsHistory }) => {
  const [transferType, setTransferType] = useState(1);

  return (
    <div>
      <Form setIsHistory={setIsHistory} />
    </div>
  );
};

export default Transfer;
