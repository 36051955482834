import React, { useState, useEffect } from "react";
import "./table.scss";
import moment from "moment";
import Details from "./Details";
import { useSelector } from "react-redux";
import { useLazyGetClientTransactionsDetailsQuery } from "services/clientApi";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import PdfBit from "shared/PdfDoc/PdfBit.jsx";
import formatNumber from "../../../helpers/NumberFormat";

const Table = ({ transactions, setIsHistory }) => {
  const [getDetails, { data: details }] =
    useLazyGetClientTransactionsDetailsQuery();
  const [openModal, setOpenModal] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const triggerModal = () => {
    setOpenModal(!openModal);
  };

  const getDetailsFunc = (id) => {
    triggerModal();
    getDetails(id);
  };

  return (
    <div className="table-wrapper">
      <Details
        details={details}
        openModal={openModal}
        triggerModal={triggerModal}
      />

      <div className="p-3">
        {isMobile ? (
          <button
            onClick={() => setIsHistory(false)}
            className="btn btn-primary"
          >
            Transfer
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="table-wrapper-h">
        <table className="table m-auto ">
          <thead className="">
            <tr className="bg-primary">
              <th scope="col " className="text-white fw-normal text-center">
                ID
              </th>
              <th scope="col" className="text-white fw-normal text-center">
                Amount
              </th>
              <th scope="col" className="text-white fw-normal text-center">
                Status
              </th>
              <th scope="col" className="text-white fw-normal text-center">
                Type
              </th>
              <th scope="col" className="text-white fw-normal text-center">
                Date
              </th>
              <th scope="col" className="text-white fw-normal text-center"></th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((el, index) => (
              <tr key={index}>
                <th className="text-primary text-center" scope="row">
                  {el?.id}
                </th>
                <td className=" fw-bold text-center">
                  {formatNumber(el?.amount)} €
                </td>
                <td className="  text-center text-orange">Pending</td>
                <td className="  bg-light text-center text-primary">
                  {el?.type === "in" ? "Incoming" : "Reuqested"}
                </td>
                <td className=" fw-bold text-center">
                  {" "}
                  {moment(el.created_at).format("DD/MM/YYYY")}
                </td>
                <td className="">
                  <div
                    className="d-flex justify-content-center gap-2"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <i
                      className="info fa-solid fa-circle-info"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Details"
                      onClick={() => getDetailsFunc(el.id)}
                    ></i> */}
                    <PDFDownloadLink
                      document={<PdfBit details={el} />}
                      fileName={"Unibank"}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <i
                            className="download fa-solid fa-download"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download Your Pdf"
                          ></i>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
