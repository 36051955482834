import React, { useState } from "react";
import { useGetAdminMainQuery, useUploadLogoMutation } from "services/mainApi";
import FileBase64 from "react-file-base64";
import { toast } from "react-toastify";
import "./adminprofile.scss";

const ProfileCmp = () => {
  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = useGetAdminMainQuery();
  const [handleLogo, setHandleLogo] = useState("");

  const [uploadLogo] = useUploadLogoMutation();

  const handleChange = (base64) => {
    setHandleLogo(base64);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await uploadLogo({
      white_logo: handleLogo.split(",")[1],
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Uploaded Succesfully");
    }
  };
  return (
    <div className="w-95 border m-auto bg-white rounded admin-profile">
      <div className="m-4">
        {" "}
        <h4>Your Profile Information</h4>
      </div>
      <div>
        <form className="mt-4 w-75 text-center m-auto ">
          {" "}
          <div className="w-100">
            <div className="text-start my-3">
              <label className="">
                {" "}
                <p>First Name</p>
              </label>
              <input
                type="text"
                className="form-control border text-muted w-100"
                placeholder="First Name"
                name="text"
                value={main?.user?.first_name}
                readonly
              />
            </div>
          </div>
          <div className="w-100">
            <div className="text-start my-3">
              <label className="">
                {" "}
                <p>Last Name</p>
              </label>
              <input
                type="text"
                className="form-control border text-muted w-100"
                placeholder="Last Name"
                name="last_name"
                value={main?.user?.last_name}
                readonly
              />
            </div>
            <div className="text-start my-3">
              <label>
                <p>Email</p>{" "}
              </label>
              <input
                type="text"
                className="form-control border text-muted w-100 "
                placeholder="Email"
                name="email"
                value={main?.user?.email}
                readonly
              />
            </div>
          </div>
        </form>
        <div className="w-75 m-auto">
          <label className="form-label" htmlFor="customFile">
            Please Upload Logo
          </label>

          {/* <input
            onChange={(e) => setHandleLogo(e.target.value)}
            type="file"
            className="form-control"
            id="customFile"
          /> */}
        </div>
        <div className="w-75 m-auto">
          <FileBase64 onDone={({ base64 }) => handleChange(base64)} />
        </div>
        <div className="text-start w-75 m-auto">
          <button onClick={handleSubmit} className="btn btn-primary my-4">
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCmp;
