import React, { useState, useEffect } from "react";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import Header from "shared/Header/Header.jsx";
import Transaction from "components/Transactions/TransactionsCmp.jsx";
import Bitcoin from "../Bitcoin/Bitcoin";
import { useSelector } from "react-redux";

const Transactions = () => {
  const [isHistory, setIsHistory] = useState(false);
  const [status, setStatus] = useState("Transfer");
  const [transferType, setTransferType] = useState("money");
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const openHistory = () => {
    setIsHistory(true);
    setStatus("History");
  };

  const openTransfer = () => {
    setIsHistory(false);
    setStatus("Transfer");
  };
  const headerButtons = [
    { name: "Transfer", func: openTransfer },
    { name: "History", func: openHistory },
  ];

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };

  return (
    <>
      {transferType === "money" ? (
        <div className="w-100 d-flex ">
          {isMobile ? (
            openMobileSide ? (
              <MobileSide openSideBar={openSideBar} />
            ) : (
              ""
            )
          ) : (
            <Sidebar />
          )}
          <div className="w-100 bg-light">
            <Header
              openSideBar={openSideBar}
              openMobileSide={openMobileSide}
              isHistory={isHistory}
              headerButtons={headerButtons}
              status={status}
            />
            <div className="w-95 m-auto bg-white p-4 ">
              <div className="d-flex justify-content-start gap-5 w-100 ">
                <span onClick={() => setTransferType("money")} type="button">
                  <h6>Wire Transfer</h6>
                </span>
              </div>
              <Transaction setIsHistory={setIsHistory} isHistory={isHistory} />
            </div>
          </div>
        </div>
      ) : (
        <Bitcoin
          transferType={transferType}
          setTransferType={setTransferType}
        />
      )}
    </>
  );
};

export default PrivateRoute(Transactions);
