import React, { useState } from "react";
import "./TransactionsCmp.scss";
import History from "./History/History";
import Transfer from "./Transfer/Transfer";

const Transactions = ({ isHistory, setIsHistory }) => {
  return (
    <div>
      <div className="w-100 h-100 ">
        {isHistory ? (
          <History setIsHistory={setIsHistory} />
        ) : (
          <Transfer setIsHistory={setIsHistory} />
        )}
      </div>
    </div>
  );
};

export default Transactions;
