import React, { useEffect, useState } from "react";
import "./companies.scss";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetClientsListQuery,
  useLoginAsClientMutation,
  useApproveOtpMutation,
  useGetAdminNotificationQuery,
  useGetAdminMainQuery,
} from "services/mainApi";
import { toast } from "react-toastify";

import moment from "moment";

const Companies = () => {
  const navigate = useNavigate();

  const [getClients, { data: clients, isLoading: loading, isError: error }] =
    useLazyGetClientsListQuery();

  const [loginAsClient, { data: token, isLoading: loadingClient }] =
    useLoginAsClientMutation();

  const { data: mainDomain } = useGetAdminMainQuery();

  const { data: main } = useGetAdminNotificationQuery();

  const [approveOtp, { isLoading: loadingReq }] = useApproveOtpMutation();

  const [start, setStart] = useState(0);
  const [search, setSearch] = useState("");
  const [requestName, setRequestName] = useState("");

  const take = 5;

  useEffect(() => {
    getClients({ start, take, search: search.toLowerCase() });
  }, [start]);

  function increment(el) {
    if (start < clients?.count - 5) {
      setStart(start + el);
    }
  }

  function decrement(el) {
    if (start > 0) {
      setStart(start - el);
    }
  }

  const approveOtpFunc = async (data) => {
    const result = await approveOtp({
      id: requestName.id,
      confirm_otp: data,
    });
    if ("error" in result) {
      console.log("errror");
    } else {
      setRequestName("");
      toast.success("Approved Succesfully");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const submitSearch = (e) => {
    e.preventDefault();
    getClients({ search, start, take });
  };

  // const logInAsClientFunc = (id)=>{
  //   loginAsClient(id)
  // }

  const logInAsClient = async (id) => {
    const result = await loginAsClient(id);

    if ("error" in result) {
      console.log("errror");
    } else {
      if (window.location.origin.includes("localhost")) {
        toast.success("Logged In Succesfully");
        window.open(
          "http://localhost:1234/?isClient=true&access_token=" +
            result.data.access_token
        );
      } else {
        toast.success("Logged In Succesfully");
        window.open(
          `${mainDomain?.client_domain}/?isClient=true&access_token=` +
            result.data.access_token
        );
      }
    }
  };

  return (
    <div className="mt-5 w-95 m-auto">
      {requestName?.id ? (
        <div className="w-50  approve shadow">
          <div className="text-end">
            <i
              type="button"
              onClick={() => setRequestName(null)}
              className="fa-solid fa-xmark fs-1 m-2"
            />
          </div>
          <h3 className="text-center">
            <strong className="text-primary">
              {requestName?.first_name + " " + requestName?.last_name}
            </strong>{" "}
            requested to login
          </h3>
          <div className="w-75 m-auto p-3  text-center">
            <div className="d-flex gap-2 justify-content-center">
              <button
                onClick={() => approveOtpFunc(true)}
                className="btn btn-primary"
              >
                {loadingReq ? "Loading..." : "Approve"}
              </button>
              <button
                onClick={() => approveOtpFunc(false)}
                className="btn  btn-danger border-0"
              >
                Dissaprove
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-between">
        <div className="d-flex gap-4">
          <h4 className="my-3">All Clients</h4>{" "}
          <i
            onClick={() => navigate("/admin/create-client")}
            data-toggle="tooltip"
            data-placement="top"
            title="Add Client"
            style={{ fontSize: "30px", cursor: "pointer" }}
            className="mt-3 fa-solid fa-plus"
          />
        </div>

        <div className="">
          <div className="input-group mt-3">
            <input
              onChange={handleChange}
              className="form-control form-control-sm"
              placeholder="Search here..."
            />
            <div className="input-group-addon border-bottom">
              {" "}
              <button
                onClick={submitSearch}
                className="btn bg-transparent border-0"
              >
                <i
                  type="button"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  className="fa-solid fa-magnifying-glass mt-2 mx-2"
                ></i>
              </button>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      <div className="bg-white shadow-sm py-4 px-4">
        {clients?.clients?.map((el, index) => (
          <div
            key={index}
            className="company position-relative shadow-sm d-flex justify-content-between bg-light w-100 m-auto mt-4"
          >
            {el?.detail?.confirm_otp === false ? (
              <div
                onClick={() => setRequestName(el)}
                className="noti-circle text-center"
              >
                New Request
              </div>
            ) : (
              ""
            )}

            <div className="mt-4 mb-2  mx-4 w-50">
              <div className="circle">
                {el.first_name.charAt(0)} {el.last_name.charAt(0)}
              </div>
            </div>
            <div className="mt-4 w-50 position-relative">
              <h2>
                {el.first_name} {el.last_name}
              </h2>

              <div className="d-flex">
                <small
                  className="text-grey
                "
                >
                  {moment(el.created_at).format("DD/MM/YYYY")}
                </small>
              </div>
            </div>

            <div className="mt-4 mx-4 w-50">
              <div>
                <button
                  onClick={() => navigate(`/admin/transactions/${el.id}`)}
                  className="btn btn-primary mt-3 mx-2"
                >
                  Show Transactions
                </button>
                <button
                  onClick={() => navigate(`/admin/update-client/${el.id}`)}
                  className="btn btn-secondary mt-3"
                >
                  Edit Client
                </button>
                <button
                  onClick={() => logInAsClient(el.id)}
                  className="btn btn-success border-0 mt-3 mx-2"
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        <div className="mt-3 mx-2">
          <h4 className="text-primary">Total Clients: {clients?.count}</h4>
        </div>
        <nav aria-label="Page navigation example" className="mt-3">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <a onClick={() => decrement(10)} className="page-link" href="#">
                Previous
              </a>
            </li>

            <li className="page-item">
              <a onClick={() => increment(5)} className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Companies;
