import React from "react";
import News from "./News";
import WhyInvest from "./WhyInvest";

const Investments = () => {
  return (
    <div style={{ overflow: "auto" }}>
      <div>
        <News />
        <WhyInvest />
      </div>
    </div>
  );
};

export default Investments;
