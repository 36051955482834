import React, { useState } from "react";
import History from "./History/History.jsx";
import Transfer from "./Transfer/Transfer.jsx";

const BitcoinCmp = ({ isHistory, setIsHistory }) => {
  return (
    <div>
      <div className="d-flex justify-content-between  w-80 m-auto mt-5"></div>
      <div className="mt-5 m-2 ">
        {isHistory ? (
          <History setIsHistory={setIsHistory} />
        ) : (
          <Transfer setIsHistory={setIsHistory} />
        )}
      </div>
    </div>
  );
};

export default BitcoinCmp;
