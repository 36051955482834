import React, { useState, useEffect } from "react";
import "./header.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import {
  useGetMainQuery,
  useGetNotificationsQuery,
  useReadNotificationClientMutation,
} from "services/clientApi";
import {
  useGetAdminNotificationQuery,
  useReadNotificationMutation,
} from "services/mainApi";

const Header = ({
  headerButtons,
  status,
  isAdmin,
  openSideBar,
  openMobileSide,
}) => {
  const navigate = useNavigate();

  // const { data: main, isLoading: loading, isError: error } = useGetMainQuery();
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");

  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = isAdmin ? "" : useGetMainQuery();
  const [openDropDown, setOpenDropDown] = useState(false);
  const {
    data: notificationAdmin,
    isLoading: loadingNoti,
    isError: errorNoti,
  } = isAdmin ? useGetAdminNotificationQuery() : useGetNotificationsQuery();

  // const { data: notificationClient } = useGetNotificationsQuery();

  const [makeNotificationSeen] = isAdmin
    ? useReadNotificationMutation()
    : useReadNotificationClientMutation();

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const logOut = () => {
    if (isAdmin) {
      localStorage.clear();
      window.location.href = "https://techno-robotix.tech";
    } else {
      localStorage.removeItem("isClient");
      localStorage.removeItem("auth_client");
      navigate("/login");
    }
  };

  const handleSubmit = async (id) => {
    // event.preventDefault();

    const result = await makeNotificationSeen({ notification_id: [id] });

    if ("error" in result) {
      console.log("errror");
    } else {
      if (notificationAdmin?.count === 0) {
        setOpenDropDown(false);
      }
    }
  };

  let notificationArray = notificationAdmin?.notifications?.map((el) => el.id);

  const clearMessage = async (id) => {
    // event.preventDefault();

    const result = await makeNotificationSeen({
      notification_id: notificationArray,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      if (notificationAdmin?.count === 0) {
        setOpenDropDown(false);
      }
    }
  };

  const clearMessageAdmin = async (id) => {
    // event.preventDefault();

    const result = await makeNotificationSeen({
      notification_id: notificationArray,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      if (notificationAdmin?.count === 0) {
        setOpenDropDown(false);
      }
    }
  };

  const barElements = [
    {
      name: "Home",
      className: "icon-dashboard",
      path: "/",
      icon: "fa-solid fa-house",
    },

    {
      name: "Transactions",
      className: "icon-transactions-v2",
      path: "/transactions",
      icon: "fa-solid fa-right-left",
    },

    {
      name: "Investments",
      className: "icon-projects",
      path: "/investments",
      icon: "fa-solid fa-chart-line",
    },
    {
      name: "Profile",
      className: "icon-projects",
      path: "/profile",
      icon: "fa-regular fa-user",
    },
  ];

  const adminBarElements = [
    {
      name: "Home",
      className: "icon-dashboard",
      path: "/admin/home",
      icon: "fa-solid fa-house",
    },

    {
      name: "Transactions",
      className: "icon-transactions-v2",
      path: "/admin/transactions",
      icon: "fa-solid fa-money-bill-transfer",
    },

    {
      name: "Profile",
      className: "icon-projects",
      path: "/admin/profile",
      icon: "fa-regular fa-user",
    },
    {
      name: "Settings",
      className: "icon-projects",
      path: "/settings",
      icon: "fa-solid fa-gears",
    },
  ];

  const locationController =
    window.location.pathname === "/" ? "/" : window.location.pathname;

  return (
    <div className=" w-100  d-flex justify-content-between  header  shadow-sm mb-5 bg-red ">
      {/* <div className="w-100 text-orange" style={{ fontSize: "20px" }}>
        {" "}
        &#187; {capitalized}
      </div> */}
      <div
        className={`d-flex justify-content-start gap-1   ${
          isMobile ? "w-25  " : "w-75  m-auto"
        }`}
      >
        {isMobile ? (
          <span
            type="button"
            className="m-3"
            onClick={() => openSideBar(!openMobileSide)}
          >
            {openMobileSide ? "" : <i className="fa-solid fa-bars fs-2" />}
          </span>
        ) : (
          <div className="text-start">
            {headerButtons
              ? headerButtons?.map((el, index) => (
                  <button
                    key={index}
                    className={`navigation-button btn btn-primary ${
                      el.name === status
                        ? "bg-light-blue text-primary "
                        : "bg-transparent text-dark"
                    }  border-0 rounded mx-4 fw-bold`}
                    onClick={el.func}
                  >
                    {el?.name}
                  </button>
                ))
              : // : (isAdmin ? adminBarElements : barElements)?.map((el, index) => (
                //     <button
                //       key={index}
                //       className={`navigation-button btn btn-primary ${
                //         el.path === locationController
                //           ? "bg-light-blue text-primary "
                //           : "bg-transparent text-dark"
                //       }  border-0 rounded mx-1 fw-bold`}
                //       onClick={() => navigate(el.path)}
                //     >
                //       {el?.name}
                //     </button>
                //   ))}
                ""}
          </div>
        )}
      </div>
      {screenWidth === "xs" ? (
        <div className="d-flex p-2  justify-content-between w-50  ">
          <h6 className="text-grey mt-2   ">
            {" "}
            Hello,{" "}
            <strong className="text-dark">
              {main?.user.first_name || ""}!
            </strong>
          </h6>
          <div className="mt-1   position-relative">
            <i
              onClick={() => setOpenDropDown(!openDropDown)}
              type="button"
              className="fa-regular fa-bell text-grey"
              style={{ fontSize: "30px" }}
            />
            {notificationAdmin?.count > 0 ? (
              <span
                onClick={() => setOpenDropDown(!openDropDown)}
                type="button"
                className="notification"
              >
                {notificationAdmin?.count}
              </span>
            ) : (
              ""
            )}

            <div className=" noti-drop dropdown d-flex justify-content-end  fs-2">
              <div
                className={` dropdown-menu w-100 mt-4 ${
                  openDropDown ? "show" : ""
                } `}
                aria-labelledby="dropdownMenuButton"
              >
                <div className="d-flex justify-content-between">
                  <span
                    type="button"
                    onClick={() => setOpenDropDown(!openDropDown)}
                    className="fs-2 mx-2 "
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                  <div
                    onClick={() => clearMessage()}
                    type="button"
                    className="text-end text-grey mt-2 mx-2"
                  >
                    Clear All
                  </div>
                </div>
                {isAdmin ? (
                  notificationAdmin.count === 0 ? (
                    <p className="text-grey text-center border-bottom mt-1">
                      No Notification
                    </p>
                  ) : (
                    notificationAdmin?.notifications?.map((item, index) => (
                      <div className="border-bottom" key={index}>
                        <a
                          onClick={() => handleClick(item.id)}
                          className="dropdown-item"
                          href="#"
                          style={{ fontSize: "15px" }}
                        >
                          <h5 className="text-primary">{item.title}</h5>
                        </a>

                        <div className="grey-bg w-100 m-auto d-flex gap-4">
                          {" "}
                          <p className="pt-1 mx-2">{item.description}</p>
                          <button
                            onClick={() => handleSubmit(item.id)}
                            className="btn btn-primary text-white btn-sm m-2"
                          >
                            Read
                          </button>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            isMobile ? "w-100 " : "w-75"
          } d-flex justify-content-end py-3 gap-4 mx-2  `}
        >
          <button
            onClick={() => navigate("/transactions")}
            className="btn btn-primary btn-sm shadow-lg custom-rounded transfer-header"
          >
            Transfer
          </button>
          <h5 className="text-grey mt-1 fs-4">
            {" "}
            Hello,{" "}
            <strong className="text-dark">
              {main?.user.first_name || ""}!
            </strong>
          </h5>

          <div className="mt-1  position-relative">
            <i
              onClick={() => setOpenDropDown(!openDropDown)}
              type="button"
              className="fa-regular fa-bell  text-grey"
              style={{ fontSize: "30px" }}
            />
            {notificationAdmin?.count > 0 ? (
              <span
                onClick={() => setOpenDropDown(!openDropDown)}
                type="button"
                className="notification"
              >
                {notificationAdmin?.count}
              </span>
            ) : (
              ""
            )}

            <div className=" noti-drop dropdown d-flex justify-content-end  fs-2">
              <div
                className={` dropdown-menu w-100 mt-4 ${
                  openDropDown ? "show" : ""
                } `}
                aria-labelledby="dropdownMenuButton"
              >
                <div className="d-flex justify-content-between">
                  <span
                    type="button"
                    onClick={() => setOpenDropDown(!openDropDown)}
                    className="fs-2 mx-2"
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                  <div
                    onClick={() => clearMessage()}
                    type="button"
                    className="text-end text-grey mt-2 mx-2"
                  >
                    Clear All
                  </div>
                </div>
                {notificationAdmin?.count === 0 ? (
                  <p className="text-grey text-center border-bottom mt-1">
                    No Notification
                  </p>
                ) : (
                  notificationAdmin?.notifications?.map((item, index) => (
                    <div className="border-bottom" key={index}>
                      <a
                        onClick={() => handleClick(item.id)}
                        className="dropdown-item"
                        href="#"
                        style={{ fontSize: "15px" }}
                      >
                        <h5 className="text-primary">{item.title}</h5>
                      </a>

                      <div className="grey-bg w-100 m-auto d-flex gap-4">
                        <div className=" w-75">
                          {" "}
                          <p className="pt-1 mx-2">{item.description}</p>
                          <p
                            style={{ fontSize: "13px" }}
                            className="mx-2 text-grey"
                          >
                            {moment(item.created_at).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </p>
                        </div>
                        <div>
                          <button
                            onClick={() => handleSubmit(item.id)}
                            className="btn btn-primary text-white btn-sm m-2"
                          >
                            Read
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div
            type="button"
            className=" d-flex gap-4 justify-content-end mt-1 "
            onClick={logOut}
          >
            {" "}
            <p className="light-grey mt-1 fw-bold">Log Out</p>
            <i
              style={{ fontSize: "30px" }}
              className="text-primary fa-solid fa-power-off"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
