import React from "react";
import PrivateRouteAdmin from "shared/PrivateRouteAdmin/PrivateRouteAdmin.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import ProfileCmp from "components/admin/Profile/ProfileCmp.jsx";

const AdminProfile = () => {
  return (
    <div className="w-100 d-flex ">
      <Sidebar isAdmin={true} />
      <div className="w-100 bg-light">
        <Header isAdmin={true} />
        <ProfileCmp />
      </div>
    </div>
  );
};

export default PrivateRouteAdmin(AdminProfile);
