import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formatNumber from "../../../helpers/NumberFormat";
import ModalV2 from "../../shared/Modal/ModalV2";
import { useTransferMessageMutation } from "services/clientApi";

import "./finish.scss";

const Finish = ({ message, attentionData }) => {
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");
  const [sendTransfer] = useTransferMessageMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (screenWidth === "sm" || screenWidth === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const submitMessage = async (data) => {
    setLoading(true);
    event.preventDefault();
    const result = await sendTransfer({
      transaction_id: attentionData.id,
      client_response: data,
    });

    if ("error" in result) {
      navigate("/");
      console.log("errror");
    } else {
      navigate("/");
      setLoading(false);
    }
  };

  return (
    <div className="h-75 finish">
      <div className={`text-center  d-flex justify-content-center gap-3`}>
        {isMobile ? (
          ""
        ) : (
          <div className="circle shadow">
            <i style={{ fontSize: "90px" }} className="fa-solid fa-check " />
          </div>
        )}
        <div className="modal-wrapper">
          <div className="w-75 m-auto   d-flex justify-content-center ">
            <div className={`specific-modal `}>
              <div className="header position-relative d-flex justify-content-center">
                <div
                  className="text-end position-absolute"
                  style={{ right: "2%" }}
                >
                  {/* <span
                    className="fw-bold text-end mx-4"
                    style={{ color: "white", fontSize: "50px" }}
                    aria-hidden="true"
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    &times;
                  </span>{" "} */}
                </div>
                <div className="w-100 text-center">
                  {" "}
                  <span
                    onClick={() => navigate("/")}
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    &#9888;
                  </span>
                </div>
              </div>{" "}
              <div className="body ">
                <h4 className="custom-danger mt-2 fw-bold">Attention</h4>
                <div className="w-95 text-start m-auto">
                  <h4 className="text-dark fw-bold px-4">
                    Transfer Request Pending!
                  </h4>
                </div>
                <p className="w-95 m-auto text-start text-dark mt-4 px-4">
                  Please note that your transfer request is currently pending.
                  <br />
                  To proceed further and complete the transfer, you must fulfill
                  the maintenance commission fee requirement.
                </p>
                <div className="d-flex w-95 m-auto  gap-2 mt-5 px-4 ">
                  <div
                    className={`${
                      isMobile ? "w-75" : "w-50"
                    } d-flex justify-content-start w-50`}
                  >
                    <p
                      className={`fw-bold text-dark  ${
                        isMobile ? "w-70 " : "w-40"
                      } text-start`}
                    >
                      Requested Amount:
                    </p>
                    <p className={`text-dark `}>
                      {formatNumber(attentionData.amount)} €
                    </p>
                  </div>
                </div>
                <div className=" w-95 m-auto px-4  gap-2">
                  <div
                    className={`${
                      isMobile ? "w-75" : "w-50"
                    } d-flex justify-content-start w-50`}
                  >
                    <p
                      className={`fw-bold text-dark ${
                        isMobile ? "w-70 " : "w-40"
                      } w-40 text-start`}
                    >
                      Commision:
                    </p>
                    <p className="text-dark">
                      {formatNumber(attentionData.outgoing_fee)} %
                    </p>
                  </div>
                </div>
                <div className="d-flex w-95 m-auto justify-content-start gap-2 px-4">
                  <div
                    className={`${
                      isMobile ? "w-75" : "w-50"
                    } d-flex justify-content-start w-50`}
                  >
                    <p
                      className={`fw-bold text-dark ${
                        isMobile ? "w-70 " : "w-40"
                      } w-40 text-start`}
                    >
                      {" "}
                      Transaction Fee:
                    </p>
                    <p className="text-dark">
                      {(attentionData?.amount * attentionData?.outgoing_fee) /
                        100}{" "}
                      €
                    </p>
                  </div>
                </div>
              </div>
              <div className="footer w-100 text-center ">
                <h4 className="text-dark fw-bold">Do you want to continue?</h4>
                <div className="d-flex justify-content-center  gap-2 w-30 m-auto mb-2">
                  {loading ? (
                    <div style={{ padding: "50px" }}>
                      <div
                        style={{ width: "50px", height: "50px" }}
                        className="spinner-border text-danger"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h4
                        onClick={() => submitMessage(true)}
                        type="button"
                        className="w-50 custom-danger border-bottom"
                      >
                        {loading ? "Loading..." : "Yes"}
                      </h4>
                      <h4
                        onClick={() => submitMessage(false)}
                        type="button"
                        className="w-50 custom-danger border-bottom"
                      >
                        No
                      </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <h1 style={{ fontSize: ` ${isMobile ? "50px" : "90px"}` }}>
            Thank You!
          </h1>
          <h4>
            {message ? message : "The transfer was performed succesfully"}{" "}
          </h4>
        </div> */}
      </div>
    </div>
  );
};

export default Finish;
