import React from "react";
import PrivateRouteAdmin from "shared/PrivateRouteAdmin/PrivateRouteAdmin.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import UpdateClient from "components/admin/Client/UpdateClient.jsx";

const Update = () => {
  return (
    <div className="w-100 d-flex ">
      <Sidebar isAdmin={true} />
      <div className="w-100 bg-light">
        <Header isAdmin={true} />
        <UpdateClient />
      </div>
    </div>
  );
};

export default PrivateRouteAdmin(Update);
