import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenWidth: (() => {
    let mediaQuery = "xxl";
    if (window.innerWidth < 576) mediaQuery = "xs";
    else if (window.innerWidth < 768) mediaQuery = "sm";
    else if (window.innerWidth < 992) mediaQuery = "md";
    else if (window.innerWidth < 1200) mediaQuery = "lg";
    else if (window.innerWidth < 1400) mediaQuery = "xl";
    return mediaQuery;
  })(),
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setScreenWidth: (state, action) => {
      let mediaQuery = "xxl";

      if (action.payload < 576) mediaQuery = "xs";
      else if (action.payload < 768) mediaQuery = "sm";
      else if (action.payload < 992) mediaQuery = "md";
      else if (action.payload < 1200) mediaQuery = "lg";
      else if (action.payload < 1400) mediaQuery = "xl";

      return {
        ...state,
        screenWidth: mediaQuery,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { openModal, setRole, setScreenWidth } = commonSlice.actions;

export default commonSlice.reducer;
