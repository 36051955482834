import React, { useState, useEffect } from "react";
import Table from "./Table";
import Modal from "shared/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./table.scss";
import {
  useGetMainQuery,
  useLazyGetSingleClientTransactionsQuery,
} from "services/clientApi";
import { toast } from "react-toastify";

const History = ({ setIsHistory }) => {
  const [openModal, setOpenModal] = useState(false);
  const { data: main, isLoading: loading, isError: error } = useGetMainQuery();
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  const [filterData, setFilterData] = useState("");
  const dispatch = useDispatch();
  const [getTransactions, { data: transactions }] =
    useLazyGetSingleClientTransactionsQuery();
  const handleChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);
  const triggerModal = () => {
    setOpenModal(!openModal);
  };

  const handleSubmitFilter = async (event) => {
    event.preventDefault();
    const result = await getTransactions({
      from: filterData.from,
      to: filterData.to,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Updated Succesfully");
      triggerModal();
    }
  };

  useEffect(() => {
    getTransactions(main?.user?.id);
  }, []);

  return (
    <div className="history-wrpper">
      <Modal open={openModal} handleCancel={triggerModal}>
        <div className="w-100 m-auto text-center">
          <h4>Filter By Date</h4>
        </div>
        <form
          className={`mt-4 w-75 text-center m-auto ${
            screenWidth === "xs" ? "" : "d-flex justify-content-between"
          } `}
        >
          {" "}
          <div className="w-100">
            <label className="fw-bold"> From date</label>
            <input
              type="date"
              className="form-control border text-muted w-100"
              placeholder="Select Date"
              name="from"
              onChange={handleChange}
            />
          </div>
          <div className="w-100">
            <label className="fw-bold"> To date</label>
            <input
              type="date"
              className="form-control border text-muted w-100"
              placeholder="Select Date"
              name="to"
              onChange={handleChange}
            />
          </div>
        </form>
        <div className="mt-5 m-auto w-100 text-end">
          <button onClick={handleSubmitFilter} className="btn btn-primary mx-4">
            Submit
          </button>
        </div>
      </Modal>
      <div className={`${screenWidth === "xs" ? "d-flex" : "d-flex"} w-100`}>
        {/* <div className="w-50 d-flex justify-content-between ">
          <div
            className={`form-group text-start mt-3  ${
              screenWidth === "xs" ? "" : "mx-4"
            }`}
          >
            <div className="input-group">
              <input
                className="form-control form-control-sm"
                placeholder="Search here..."
              />
              <div className="input-group-addon border-bottom">
                <i
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  className="fa-solid fa-magnifying-glass mt-2 mx-2"
                ></i>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`w-100 mt-3 d-flex  ${
            screenWidth === "xs"
              ? "justify-content-between"
              : "justify-content-between mx-5"
          }  gap-4`}
        >
          {" "}
          <div className="w-50">
            {isMobile ? (
              <button
                onClick={() => setIsHistory(false)}
                className="btn btn-primary btn-sm mt-2"
              >
                Transfer
              </button>
            ) : (
              ""
            )}
          </div>
          {/* <div className=" w-50 d-flex gap-2">
            <p className="text-grey mt-1">Type</p>
            <div className="w-25">
              {" "}
              <button
                className="btn btn-primary dropdown-toggle btn-sm"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton"
              >
                <a class="dropdown-item" href="#">
                  Incoming
                </a>
                <a class="dropdown-item" href="#">
                  Outgoing
                </a>
              </div>
            </div>
          </div> */}
          <div
            onClick={triggerModal}
            className="mx-1 "
            style={{ cursor: "pointer" }}
          >
            <i
              className="fa-solid fa-filter text-light-blue"
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        </div>
      </div>

      <Table main={main} transactions={transactions} />
    </div>
  );
};

export default History;
