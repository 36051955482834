import React from "react";
import Welcome from "../../Home/Welcome";
import Companies from "./Companies";
import { useGetAdminMainQuery } from "services/mainApi";

const HomeAdminComp = () => {
  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = useGetAdminMainQuery();
  return (
    <div>
      <Welcome main={main} />
      <Companies />
    </div>
  );
};

export default HomeAdminComp;
