import React, { useState, useEffect } from "react";
import { useScheduleCallMutation } from "services/clientApi";
import "./scheduler.scss";
import moment from "moment";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import Calendar from "../../components/Calendar/Calendar";

const Scheduler = () => {
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [isMobile, setIsMobile] = useState("");
  const [data, setData] = useState("");
  const [schedule] = useScheduleCallMutation();
  const [openMobileSide, setOpenMobileSide] = useState(false);

  const [openCalendar, setOpenCalendar] = useState(true);

  function triggerCalendar() {
    setOpenCalendar(!openCalendar);
  }

  const id = 486;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    const result = await schedule({
      start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
      title: data.title,
      description: data.description,
    });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Succesfully Created");
      setOpenCalendar(true);
    }
  };

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  return (
    <div className="w-100 d-flex ">
      {isMobile ? (
        openMobileSide ? (
          <MobileSide openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar />
      )}
      <div className="w-100 bg-light">
        <Header openSideBar={openSideBar} openMobileSide={openMobileSide} />
        <div className="text-end mx-5">
          <button onClick={triggerCalendar} className="btn btn-primary">
            {openCalendar ? " + Schedule a Call" : "Calendar"}
          </button>
        </div>{" "}
        {openCalendar ? (
          <Calendar />
        ) : (
          <div className="w-100 modal-wrap-schedule">
            <div
              className={`w-75 ${
                isMobile ? "" : "d-flex"
              } m-auto schedule shadow `}
            >
              <div className={` ${isMobile ? "w-100" : "w-75"}`}>
                <h4 className="text-center text-dark-blue py-5">
                  Schedule your call
                </h4>

                <form className="">
                  <div className="form-group w-70 m-auto mt-4 text-start">
                    <label className="fw-bold ">Title</label>

                    <input
                      name="title"
                      onChange={handleChange}
                      type="title"
                      className="form-control border bg-white"
                    />
                  </div>

                  <div className="form-group w-70 m-auto mt-4 text-start ">
                    <label className="fw-bold">Pick date</label>

                    <input
                      onChange={handleChange}
                      name="start_date"
                      type="datetime-local"
                      className="form-control border bg-white"
                    />
                  </div>
                  <div className="form-group w-70 m-auto mt-4 text-start">
                    <label className="fw-bold">Description</label>
                    <textarea
                      onChange={handleChange}
                      type="text"
                      name="description"
                      className="form-control border bg-white"
                    />
                  </div>
                  <div className="text-center w-100 my-5">
                    <button
                      onClick={handleSumbit}
                      className="btn btn-primary btn-lg custom-rounded"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className={` ${isMobile ? "w-100" : "bg-white"}`}>
                <div
                  className="text-center"
                  style={{ marginTop: isMobile ? "10%" : "30%" }}
                >
                  <div>
                    {" "}
                    <i
                      className="fa-solid fa-calendar-check  text-dark-blue"
                      style={{ fontSize: "130px" }}
                    />
                  </div>

                  <h4 className="w-50 text-dark-blue  m-auto mt-3">
                    schedule an appointment
                  </h4>
                  <p className="w-80 m-auto text-grey mt-2">
                    Fill the form to set up an appointment with us to get the
                    best solution
                  </p>
                  {/* <div className={` ${isMobile ? " " : "mt-5"}`}>
                    <h5 className="text-dark-blue">+45644474</h5>
                    <h5 className={` ${isMobile ? "pb-5" : ""} text-green`}>
                      appointment@gmail.com
                    </h5>
                  </div> */}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivateRoute(Scheduler);
