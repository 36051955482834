import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { toast } from "react-toastify";

import { useGetClientCalendarQuery, useGetMainQuery } from "services/clientApi";

const localizer = momentLocalizer(moment);

const CalendarClient = () => {
  const [openModal, setOpenModa] = useState(false);
  const [data, setData] = useState();
  const { data: schedule } = useGetClientCalendarQuery();
  const { data: main } = useGetMainQuery();

  const event = schedule?.schedules;

  const handleChange = async (id, status_id) => {
    const result = await updateSchedule({ id, status_id: status_id });
    if ("error" in result) {
    } else {
      toast.success("Updated Succesfully");
    }
  };

  return (
    <div className="">
      <div className="mx-3">
        <h3>Calendar</h3>
      </div>

      <div className="w-95 m-auto shadow-lg bg-white p-5 mt-5 d-flex gap-3">
        <Calendar
          gap-3
          localizer={localizer}
          events={event}
          eventPropGetter={(event) => {
            const backgroundColor = event?.status?.color
              ? event?.status?.color
              : "blue";
            const color = "black";
            return { style: { backgroundColor, color } };
          }}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800, width: openModal ? 900 : "100%" }}
          onDoubleClickEvent={(event) => {
            setOpenModa(true);
            setData(event);
          }}
        />
        {openModal ? (
          <div className="w-40 m-auto shadow-lg bg-white mt-4 border rounded">
            <div className="bg-primary border rounded">
              <p className="test text-white w-95 m-auto mt-3">Event Details</p>
              <div className="w-95 m-auto mt-4">
                <h3 className="text-white">{data.title} </h3>{" "}
                <h6 className="text-white fw-light my-3">
                  <i className="fa-regular fa-clock me-2" />
                  {moment(data.start).format("DD-MM-YYYY HH:mm:ss")} -{" "}
                  {moment(data.end).format("DD-MM-YYYY HH:mm:ss")}
                  {"  "}
                  {""}
                </h6>{" "}
              </div>
            </div>
            <div className="w-95 m-auto  my-5 border-top">
              <div className="d-flex gap-1 mt-1">
                <i className="fa-regular fa-comment text-primary mt-1" />

                <h5>Description</h5>
              </div>
              <div>
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CalendarClient;
