import React, { useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePasswordMutation } from "services/auth";
import Logo from "../../assets/images/logo.png";

const Password = () => {
  const [formData, setFormData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const email = localStorage.getItem("email");

  const [submitPassowrd] = usePasswordMutation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitPassword = async (event) => {
    event.preventDefault();
    const result = await submitPassowrd({
      email: email,
      password: formData.password,
    });

    if ("error" in result) {
    } else {
      localStorage.setItem("auth_client", result.data.access_token);
      localStorage.setItem("isClient", true);
      navigate("/");
      toast.success("Logged in Succesfully");
    }
  };

  return (
    <div className=" wrapp  ">
      <div className=" content text-center shadow-lg rounded  border-0 bg-white">
        <img src={Logo} alt="" />

        <form className="w-50 m-auto mt-5 ">
          {" "}
          <small className="text-grey">
            We have send the OTP to <strong>{email}</strong>
          </small>{" "}
          <div>
            {" "}
            <small className="text-danger">Please Check Your Email</small>
          </div>
          <div className="form-group text-start mt-3">
            <label className="fw-bold">Code</label>
            <div className="input-group" id="show_hide_password">
              <input
                className="form-control"
                type={showPassword ? "" : "password"}
                name="password"
                onChange={handleChange}
              />
              <div className="input-group-addon border-bottom">
                {showPassword ? (
                  <p
                    onClick={() => setShowPassword(false)}
                    className=" show m-2 fw-bold"
                  >
                    Hide
                  </p>
                ) : (
                  <p
                    onClick={() => setShowPassword(true)}
                    className="show m-2 fw-bold"
                  >
                    Show
                  </p>
                )}{" "}
              </div>
            </div>
          </div>
          {/* <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <div className="input-group input-group-sm mt-1">
              <label
                className="checkbox-inline"
                aria-describedby="ProcessingConsultantYN"
                id="lbProcessingConsultant"
                for="ProcessingConsultantYN"
              >
                Remember Me
              </label>
            </div>
          </div> */}
          <div>
            {" "}
            <small>
              If you didn't receive the code!{" "}
              <strong
                type="button"
                onClick={() => navigate("/login")}
                className="text-danger"
              >
                Resend
              </strong>
            </small>
          </div>
          <button
            onClick={handleSubmitPassword}
            type="submit"
            className="btn btn-primary my-5 fw-bold w-25 shadow-lg"
          >
            Verify
          </button>
        </form>
      </div>
    </div>
  );
};

export default Password;
