import React, { useState } from "react";
import "./tableHome.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "shared/Loading/Loading.jsx";
import { useLazyGetClientTransactionsDetailsQuery } from "services/clientApi";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import formatNumber from "../../helpers/NumberFormat.jsx";

const Table = ({ loading, transactions }) => {
  const navigate = useNavigate();
  const [getDetails, { data: details }] =
    useLazyGetClientTransactionsDetailsQuery();

  const [openModal, setOpenModal] = useState(false);

  const triggerModal = () => {
    setOpenModal(!openModal);
  };

  const getDetailsFunc = (id) => {
    triggerModal();
    getDetails(id);
  };

  return (
    <div className=" table-wrapper-home shadow-sm rounded">
      <div className="d-flex justify-content-between">
        <div className="p-4">
          <h5>History</h5>
        </div>
        <div className=" p-4 gap-3">
          <p
            onClick={() => navigate("/transactions")}
            type="button"
            className="text-grey"
          >
            Show More
          </p>
        </div>
      </div>

      {loading ? (
        <div className="text-center mt-5">
          <Loading style={{ width: "30px", height: "30px" }} />
        </div>
      ) : (
        <div className="history-content">
          {transactions?.length > 0 ? (
            transactions?.map((el, index) => (
              <div key={index} className="w-95 m-auto border-bottom mb-2 ">
                <div className="d-flex justify-content-between my-1">
                  <div className="mt-2">
                    <div className="circle">
                      <i className=" text-primary fa-solid fa-coins" />
                    </div>
                  </div>

                  <div className="w-75">
                    <div className="container mx-2">
                      <div className="row">
                        <div className="col">
                          {" "}
                          {el.type === "out" ? (
                            <i
                              style={
                                {
                                  // transform: " rotate(20deg)",
                                }
                              }
                              className={`fa-solid fa-arrow-up text-danger`}
                            />
                          ) : (
                            <i
                              // style={{ transform: " rotate(20deg)" }}
                              className="fa-solid fa-arrow-down text-success "
                            />
                          )}
                          <strong className="mx-1 fs-5">
                            {formatNumber(el.amount)} €
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-grey">
                          {el.beneficary_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <small className="text-grey">
                      {moment(el.created_at).format("HH:mm:ss")}
                    </small>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mt-4 w-80 m-auto h-100">
              <h5 className="fw-bold">No Transactions Yet</h5>
              <p className="text-grey">
                Yor transactions history will show up once a transfer is made.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
