import React, { useState, useEffect } from "react";
import "./exchange.scss";
import { useSelector } from "react-redux";
import { useCreateBTCMutation } from "services/clientApi";
import { toast } from "react-toastify";
import { useGetMainQuery } from "services/clientApi";
import Finish from "../../Transactions/Transfer/Finish";

const Form = ({ transferType, setIsHistory }) => {
  const [data, setFormData] = useState({ currency: "BTC" });
  const [isMobile, setIsMobile] = useState("");
  const { data: main, isLoading: loading, isError: error } = useGetMainQuery();
  const [finish, setFinish] = useState(false);
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const [sendBtc] = useCreateBTCMutation();
  const handleChange = (e) => {
    setFormData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await sendBtc({ transferType, data });

    if ("error" in result) {
      console.log("error");
    } else {
      setFinish(true);
      toast.success("Created Succesfully");
    }
  };

  return (
    <div
      className={`${
        isMobile
          ? "w-95 m-uato"
          : "w-75 m-auto  shadow-sm p-3 mb-5 bg-white rounded"
      } `}
    >
      {finish ? (
        <Finish message={"Request Submitted! Our Team will Contact You Soon"} />
      ) : (
        <>
          <div className="text-end">
            <i
              style={{ fontSize: "90px" }}
              className="fa-brands  fa-bitcoin text-primary"
            />
          </div>
          <div className="">
            <div
              className={`${
                isMobile
                  ? "W-100"
                  : "w-75 m-auto mb-4 d-flex justify-content-between"
              }`}
            >
              <div className="w-100">
                {isMobile ? (
                  <button
                    className="btn btn-primary text-end"
                    onClick={() => setIsHistory(true)}
                  >
                    History
                  </button>
                ) : (
                  ""
                )}

                <h3 className="fw-bold my-3">Exchange Transfer Form</h3>
                <small>Please fill out fields to make your transfer.</small>
              </div>
              <h4 className="my-3 text-green  w-50">
                Balance: {main?.user?.balance}€
              </h4>
            </div>
            <form className="w-75 m-auto ">
              <div>
                <div className="w-100">
                  <div
                    className={` ${
                      isMobile
                        ? ""
                        : "form-group mb-2 d-flex justify-content-between gap-3"
                    }`}
                  >
                    <div className={`${isMobile ? "w-100" : "w-50"}`}>
                      <label
                        className="light-grey"
                        htmlFor="exampleInputPassword1"
                      >
                        Currency
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control text-primary"
                        placeholder="Currency"
                        name="currency"
                        onChange={handleChange}
                        value={"BTC to EURO"}
                        style={{ fontWeight: "500" }}
                      />
                    </div>
                    <div className={`${isMobile ? "w-100" : "w-50"}`}>
                      <label
                        className="light-grey"
                        htmlFor="exampleInputPassword1"
                      >
                        From
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control text-primary"
                        placeholder="Currency"
                        name="currency"
                        onChange={handleChange}
                        value={main?.user?.detail?.account_number}
                        style={{ fontWeight: "500" }}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <label className="light-grey" htmlFor="exampleInputEmail1">
                      Amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ fontWeight: "500" }}
                      placeholder="Amount"
                      name="amount"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label
                      className="light-grey"
                      htmlFor="exampleInputPassword1"
                    >
                      Network Fee
                    </label>
                    <div>
                      <div className="form-group w-100 position-relative">
                        <button
                          type="button"
                          className="form-control dropdown-toggle w-100"
                          data-toggle="dropdown"
                          style={{ fontWeight: "500", fontSize: "16px" }}
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {data?.fee ? data.fee : "Choose"}
                        </button>
                        <div className="dropdown-menu w-100">
                          <a
                            onClick={() =>
                              setFormData({ ...data, fee: "Priority" })
                            }
                            className="dropdown-item "
                            href="#"
                          >
                            Priority
                          </a>
                          <a
                            onClick={() =>
                              setFormData({ ...data, fee: "Regular" })
                            }
                            className="dropdown-item "
                            href="#"
                          >
                            Regular
                          </a>
                        </div>
                      </div>
                    </div>
                    <p className="text-primary">
                      {data.fee
                        ? data?.fee === "Regular"
                          ? "Estimated confirmation time 12+h"
                          : "Estimated confirmation time 1+h"
                        : ""}
                    </p>
                  </div>
                  <div className="form-group mb-2">
                    <label
                      className="light-grey"
                      htmlFor="exampleInputPassword1"
                    >
                      Crypto Wallet
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Crypto Wallet"
                      name="to"
                      onChange={handleChange}
                      style={{ fontWeight: "500" }}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label
                      className="light-grey"
                      htmlFor="exampleInputPassword1"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      onChange={handleChange}
                      style={{ fontWeight: "500" }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-100 text-center mt-4">
                {" "}
                <button
                  onClick={handleSubmit}
                  style={{ borderRadius: "20px" }}
                  className={`${
                    isMobile ? "w-100" : "w-25"
                  } btn make-exchange btn-primary bg-transparent text-dark my-4 shadow-lg`}
                >
                  Send
                </button>
              </div>
              <div className="text-center">
                <small className="fw-bold">
                  *Your transfer will be displayed in Transactions History
                </small>
              </div>
            </form>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default Form;
