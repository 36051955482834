import React from "react";
import Modal from "shared/Modal/Modal";
import formatNumber from "../../../helpers/NumberFormat";

import moment from "moment";
const Details = ({ main, details, openModal, triggerModal }) => {
  return (
    <div>
      {" "}
      <Modal open={openModal} handleCancel={triggerModal}>
        <div className="w-100 bg-primary custom-round d-flex justify-content-between text-white">
          {" "}
          <h4 className="px-5 text-white"> Details</h4>
          <div className="px-5">
            {" "}
            <h5>{moment(details?.created_at).format("DD/MM/YYYY")}</h5>
          </div>
        </div>
        <div>
          <div className="mt-5 mb-2 m-auto w-60 text-center  shadow-lg p-4 rounded">
            {details?.type === "out" ? (
              <div>
                <div className="d-flex  gap-3  w-100 ">
                  <div className="w-50 text-start  ">
                    <h5 className="text-dark">Amount:</h5>
                  </div>
                  <div className="w-50 text-start text-dark">
                    <h6>{formatNumber(details?.amount)} €</h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3  w-100  ">
                  <>
                    {" "}
                    <div className="w-50 text-start ">
                      <h5 className="text-dark">Commision :</h5>
                    </div>
                    <div className="w-50 text-start text-dark">
                      <h6 className=" w-25  ">({details?.outgoing_fee}%)</h6>
                    </div>
                  </>
                </div>
                <div className="d-flex  gap-3  w-100  border-bottom ">
                  <div className="w-50 text-start  ">
                    <h5 className="text-dark">Fee:</h5>
                  </div>
                  <div className="w-50 text-start text-dark">
                    <h6>{(details?.amount * details?.outgoing_fee) / 100} €</h6>
                  </div>
                </div>
                <h6 className="text-start text-success">
                  {" "}
                  {details.id === 30 ? "Paid" : ""}
                </h6>
                <div className="gap-3   mt-4">
                  <div className="d-flex ">
                    <div className="w-50 text-start ">
                      <h6 className="text-dark fw-bold">Status</h6>
                    </div>
                    <div className={`w-50 text-start `}>
                      <h6 style={{ color: details?.status?.color }}>
                        {details?.status?.name}{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="w-50 text-start ">
                    <h6 className="text-dark fw-bold">Notes</h6>
                  </div>
                  <div className="w-50 text-start mt-1">
                    <h6>{details?.description} </h6>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center gap-3">
                  <div className="w-50 text-start ">
                    <h5 className="text-green">Credit:</h5>
                  </div>
                  <div className="w-50 text-start ">
                    <h5>{formatNumber(details?.amount)} €</h5>
                  </div>
                </div>
                <div className="gap-3  mt-4">
                  <div className="w-50 text-start ">
                    <h6 className="text-dark fw-bold">Notes</h6>
                  </div>
                  <div className="w-50 text-start mt-1">
                    <h6>{details?.description} </h6>
                  </div>
                </div>
              </>
            )}

            {/* <div className="d-flex justify-content-center gap-3">
            <div className="w-50 text-start ">
              <h5 className="text-grey">Value Date:</h5>
            </div>
            <div className="w-50 text-start">
              <h5>{details?.value_date}</h5>{" "}
            </div>
          </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Details;
