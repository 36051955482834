import React, { useState, useEffect } from "react";
import "./statuses.scss";
import {
  useCreateStatusMutation,
  useGetAdminMainQuery,
  useDeleteStatusMutation,
} from "services/mainApi";

import { toast } from "react-toastify";

const Statuses = () => {
  const [openPicker, setOpenPicker] = useState(false);
  const [data, setData] = useState();

  const [createStatus] = useCreateStatusMutation();
  const {
    data: main,
    isLoading: loading,
    isError: error,
  } = useGetAdminMainQuery();

  const [deleteStatus] = useDeleteStatusMutation();
  const [statusData, setStatusData] = useState("");

  function openPickerFunc() {
    setOpenPicker(true);
  }

  useEffect(() => {
    setStatusData(main?.statuses);
  }, [main]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const result = await createStatus({ data });

    if ("error" in result) {
      console.log("errror");
    } else {
      toast.success("Succesfully Created");
    }
  };

  return (
    <div className="bg-white w-95 m-auto shadow p-5 mb-5 bg-white rounded">
      <div className="d-flex justify-content-between gap-1">
        <div className="w-60">
          <h4>Create Status</h4>
          <hr className="w-80"></hr>
          <div className="d-flex gap-4">
            <div className="form-group my-3">
              <label className="fw-bold">Name</label>
              <input
                name="name"
                className="form-control"
                placeholder="Enter Status Name"
                onChange={handleChange}
              />
            </div>
            <div className="form-group my-3">
              <label className="fw-bold">Choose Color</label>
              <div
                className="color-picker"
                type="button"
                onClick={() => setOpenPicker(true)}
                style={{
                  backgroundColor: data?.color
                    ? data.color
                    : "rgb(87, 165, 141)",
                  boxShadow: data?.color
                    ? "0 0 25px" + data.color
                    : " 0 0 25px rgb(87, 165, 141)",
                }}
              >
                {openPicker ? "" : "Color"}
                <input name="color" onChange={handleChange} type="color" />
              </div>
            </div>
            <div className="form-group my-3">
              <label className="fw-bold"></label>
              <div>
                <button onClick={handleSubmit} className="btn btn-primary mx-4">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-40">
          {" "}
          <h4>Status List</h4>
          <hr></hr>{" "}
          <div className="status-list">
            {(statusData || []).map((el, index) => {
              return (
                <div className="d-flex gap-2 border-bottom  ">
                  <div className="w-50 mx-1 ">
                    <input
                      name="name"
                      className="form-control"
                      placeholder="Enter Status Name"
                      onChange={(e) => handleStatus(e, index)}
                      value={el?.name}
                    />
                  </div>
                  <div className="d-flex w-50 gap-2 justify-content-end mx-5">
                    {el.color ? (
                      <div
                        className="color-picker-round"
                        type="button"
                        style={{
                          backgroundColor: el.color,
                          boxShadow: "0 0 25px" + el.color,
                        }}
                      ></div>
                    ) : (
                      <div></div>
                    )}
                    <i
                      onClick={() => deleteStatus(el.id)}
                      type="button"
                      className="fa-solid fa-trash  text-danger mt-1"
                    ></i>
                    <div className="btn-group">
                      <i
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button"
                        className="fa-solid fa-pencil  text-primary mt-1"
                      ></i>

                      <div className="dropdown-menu">
                        <input
                          name="name"
                          className="form-control"
                          placeholder="Enter Status Name"
                          onChange={(e) => handleStatus(e, index)}
                          value={el?.name}
                        />
                        <div
                          className="color-picker-round"
                          type="button"
                          style={{
                            backgroundColor: el.color,
                            boxShadow: "0 0 25px" + el.color,
                          }}
                        >
                          <input
                            name="color"
                            onChange={handleChange}
                            type="color"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statuses;
