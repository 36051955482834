import React, { useState, useEffect } from "react";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import InvestmentsComp from "components/Investments/InvestmentsComp.jsx";
import "./investments.scss";
import { useSelector } from "react-redux";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";

const Investments = () => {
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const screenWidth = useSelector((state) => state?.common?.screenWidth);

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };

  return (
    <div className=" investment w-100 d-flex ">
      {isMobile ? (
        openMobileSide ? (
          <MobileSide openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar />
      )}
      <div className="w-100 bg-light h-100 content">
        <Header openSideBar={openSideBar} openMobileSide={openMobileSide} />
        <InvestmentsComp />
        {/* <div>
          <h2 className="mx-2">Investments...</h2>
        </div> */}
      </div>
    </div>
  );
};

export default PrivateRoute(Investments);
