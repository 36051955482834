import React, { useEffect } from "react";
import { useLazyGetMainQuery } from "services/clientApi";

const ProfileInfo = () => {
  const [getMain, { data: main, isLoading: loading, isError: error }] =
    useLazyGetMainQuery();

  useEffect(() => {
    getMain();
  }, []);
  return (
    <div className="w-100 border m-auto bg-white rounded user-details">
      <div className="d-flex justify-content-between">
        <div className="m-4">
          {" "}
          <h4>User Details</h4>
        </div>
        <div className="mt-4 mx-4"> </div>
      </div>
      <div>
        <form className="mt-4 w-75 text-center m-auto gap-5 ">
          {" "}
          <div className="w-100">
            <div className="text-start my-3">
              <label className="light-grey"> First Name</label>
              <input
                type="text"
                className="form-control  text-muted w-100 fw-bold"
                placeholder="First Name"
                name="text"
                value={main?.user?.first_name}
              />
            </div>
            <div className="text-start my-3">
              <label className="light-grey"> Last Name </label>
              <input
                type="text"
                className="form-control text-muted w-100 fw-bold"
                placeholder="Last Name"
                name="last_name"
                value={main?.user?.last_name}
              />
            </div>

            <div className="text-start my-3">
              <label className="light-grey">Account Number</label>
              <input
                type="text"
                className="form-control   w-100 text-primary  fw-bold"
                placeholder="Account Number"
                name="account_number"
                value={main?.user?.detail?.account_number}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="text-start my-3">
              <label className="light-grey">IBAN</label>
              <input
                type="text"
                className="form-control fw-bold text-muted w-100  fw-bold"
                placeholder="IBAN"
                name="iban"
                value={main?.user?.detail?.iban}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="text-start my-3">
              <label className="light-grey">Country</label>
              <input
                type="text"
                className="form-control text-muted w-100  fw-bold"
                placeholder="Country"
                name="country"
                value={main?.user?.detail?.country}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="text-start my-3">
              <label className="light-grey">Email</label>
              <input
                type="text"
                className="form-control text-muted w-100  fw-bold"
                placeholder="Email"
                name="email"
                value={main?.user?.email}
              />
            </div>
          </div>
          <div className="text-center my-3 w-50 m-auto">
            <label></label>
            <div className="dropdown w-100 ">
              <button
                className="btn fw-bold btn-primary border-0 custom-rounded shadow dropdown-toggle w-100"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Cards
              </button>
              <div
                className="dropdown-menu w-100 "
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item">No Records Found</a>
              </div>
            </div>
          </div>
          <div className="text-center my-3 w-50 m-auto">
            <label></label>
            <div className="dropdown w-100 m-auto  ">
              <button
                className="btn btn-primary border-0 custom-rounded  fw-bold shadow dropdown-toggle w-100"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Loans
              </button>
              <div
                className="dropdown-menu w-100 "
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item">No Records Found</a>
              </div>
            </div>
          </div>
        </form>

        {/* <div className="text-end">
          <button className="btn btn-primary m-4">Edit</button>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileInfo;
