import React, { useState, useEffect } from "react";
import PrivateRouteAdmin from "shared/PrivateRouteAdmin/PrivateRouteAdmin.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import TransactionsAdminComp from "components/admin/ClientTransactions/TransactionsAdminComp.jsx";

const AdminTransactions = () => {
  return (
    <div className="w-100 d-flex ">
      <Sidebar isAdmin={true} />
      <div className="w-100 bg-light">
        <Header isAdmin={true} />
        <TransactionsAdminComp />
      </div>
    </div>
  );
};

export default PrivateRouteAdmin(AdminTransactions);
