import React, { useState, useEffect } from "react";
import PrivateRoute from "shared/PrivateRoute/PrivateRoute.js";
import Sidebar from "shared/SideBar/Sidebar.jsx";
import Header from "shared/Header/Header.jsx";
import MobileSide from "shared/MobileSide/MobileSidebar.jsx";
import ProfileInfo from "components/Profile/ProfileInfo.jsx";
import Documents from "components/Profile/Documents.jsx";
import { useGetMainQuery } from "services/clientApi";
import { useSelector } from "react-redux";

const Profile = () => {
  const [openDocuments, setOpenDocuments] = useState(false);
  const { data: main, isLoading: loading, isError: error } = useGetMainQuery();
  const screenWidth = useSelector((state) => state?.common?.screenWidth);
  const [openMobileSide, setOpenMobileSide] = useState(false);
  const [isMobile, setIsMobile] = useState("");
  const documents = main?.user?.detail?.documents;

  useEffect(() => {
    if (
      screenWidth === "lg" ||
      screenWidth === "md" ||
      screenWidth === "sm" ||
      screenWidth === "xs"
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const openSideBar = () => {
    setOpenMobileSide(!openMobileSide);
  };
  return (
    <div className="w-100 d-flex ">
      {isMobile ? (
        openMobileSide ? (
          <MobileSide openSideBar={openSideBar} />
        ) : (
          ""
        )
      ) : (
        <Sidebar />
      )}
      <div className="w-100 bg-light">
        <Header openSideBar={openSideBar} openMobileSide={openMobileSide} />
        <div className="w-95 m-auto bg-white p-4 ">
          <div className="d-flex justify-content-start gap-5 w-100 ">
            <span
              type="button"
              onClick={() => setOpenDocuments(false)}
              className={`${openDocuments ? "" : "border-bottom-active"}`}
            >
              <h6>Profile Information</h6>
            </span>
            <span
              type="button"
              onClick={() => setOpenDocuments(true)}
              className={`${openDocuments ? "border-bottom-active" : ""}`}
            >
              {" "}
              <h6>Documents</h6>
            </span>
          </div>
          {openDocuments ? (
            <Documents main={main} documents={documents} />
          ) : (
            <ProfileInfo />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute(Profile);
